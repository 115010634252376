import { ApiResponse, ApisauceInstance } from 'apisauce';

export type PostUploadResponseSuccess = {
  code: number;
  message?: string;
};

export type PostUploadResponseError = {
  error: {
    code: number;
    message: string;
    errors?: string[];
  };
};

const postUpload = ({
  formData,
  headers,
  dataType,
  provider,
  api,
}: {
  formData: FormData;
  headers: { [key: string]: string };
  dataType: string;
  provider: string;
  api: ApisauceInstance;
}): Promise<ApiResponse<PostUploadResponseSuccess, PostUploadResponseError>> =>
  api.post(`/uploads/${provider}/${dataType}`, formData, {
    headers,
  });

export default postUpload;
