import React, { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';

import Typography from 'kingpin/atoms/Typography';
import Icon from 'kingpin/atoms/Icon';
import Row from 'components/Common/Row';
import Loading from 'components/Loading/Loading';
import CurrentUserContext from 'contexts/CurrentUserContext';
import AccountPickerContext from 'contexts/AccountPickerContext';

import ExportGridContext from '../context/ExportGridContext';
import { Z_INDEX } from '../../../constants';

const ToastMessage = styled(motion.div)`
  position: absolute;
  width: fit-content;
  top: 0px;
  left: calc(50% - 100px);
  margin: auto;
  background-color: ${Colors2.Grey['2']};
  padding: 8px 32px;

  text-align: center;
  border-radius: 8px;

  box-shadow:
    0px 0px 0px 1px rgba(87, 89, 97, 0.1),
    1px 7px 20px -2px rgba(0, 0, 0, 0.4),
    0px -1px 3px -2px rgba(0, 0, 0, 0.25);

  z-index: ${Z_INDEX.TOAST};
`;

const variants = {
  exportToastOpened: { opacity: 1, top: 50 },
  exportToastClosed: {
    top: -50,
    opacity: 0,
  },
};

const ExportGridProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);

  const [isExportPending, setIsExportPending] = useState<boolean>(false);
  const [exportId, setExportId] = useState<string | undefined>();
  const [exportName, setExportName] = useState<string | undefined>();
  const [exportStatus, setExportStatus] = useState<
    'queued' | 'inProgress' | 'done'
  >('inProgress');

  const onExportDownloadFinished = useCallback(() => {
    setExportId(undefined);
    setExportName(undefined);
    setIsExportPending(false);
    setExportStatus('inProgress');
  }, []);

  const downloadFile = useCallback(
    async ({ path }: { path: string }) => {
      const fileSrc = path.split('/').slice(4).join('/');
      const url = await firebase.storage().ref(fileSrc).getDownloadURL();

      window.open(url);
      onExportDownloadFinished();
    },
    [onExportDownloadFinished],
  );

  useEffect(() => {
    if (!exportId || process.env.NODE_ENV === 'test' || isWallboardUser) {
      return;
    }

    let isActive = true;

    const listener = accountRef
      .collection('exports')
      .doc('simpleGridExport')
      .collection('v1')
      .doc(exportId)
      .onSnapshot((snapshot) => {
        if (!isActive || !snapshot.exists) {
          return;
        }

        const data = snapshot.data();
        if (!data) {
          return;
        }

        setExportName(data.name);
        setExportStatus(data.status);

        if (data.status === 'done') {
          downloadFile({ path: data.excelFilePath });
        }
      });

    return () => {
      listener();
      isActive = false;
    };
  }, [accountRef, downloadFile, exportId, isWallboardUser]);

  const onExportRequestStarted = useCallback((fileName: string) => {
    setExportName(fileName);
    setIsExportPending(true);
  }, []);
  const onExportRequestResponse = useCallback((exportId: string) => {
    setExportId(exportId);
  }, []);
  const onExportRequestFailed = useCallback(() => {
    setIsExportPending(false);
  }, []);

  return (
    <ExportGridContext.Provider
      value={{
        isExportPending,
        onExportRequestStarted,
        onExportRequestResponse,
        onExportRequestFailed,
      }}
    >
      {children}
      <ToastMessage
        variants={variants}
        animate={isExportPending ? 'exportToastOpened' : 'exportToastClosed'}
      >
        <Row centerAlign>
          <div style={{ marginRight: '8px' }}>
            {exportStatus === 'done' ? (
              <Icon icon="circleCheckmark" color={Colors2.Secondary.success} />
            ) : (
              <>{isExportPending && <Loading isTiny />}</>
            )}
          </div>

          {exportStatus === 'done' ? (
            <Typography.Header type="H4" color="white">
              {`Download Started: ${exportName}`}
            </Typography.Header>
          ) : (
            <Typography.Header type="H4" color="white">
              {`Preparing Download: ${exportName}`}
            </Typography.Header>
          )}
        </Row>
      </ToastMessage>
    </ExportGridContext.Provider>
  );
};

export default ExportGridProvider;
