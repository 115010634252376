import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import env from '../../../../../../.env.json';
import getDateBuckets from '../../../../../GoalShow/getDateBuckets';
import formatDateLabel, {
  formatVerboseWeek,
} from '../../../../../../components/V5Gadget/formatDateLabel';
import PerformanceWizardContext from '../PerformanceWizardContext';
import AccountContext from '../../../../../../contexts/AccountContext';

const useDateOptions = () => {
  const { weekStartsOn } = useContext(AccountContext);
  const { wizardState, setWizardState } = useContext(PerformanceWizardContext);
  const [dateOptions, setDateOptions] = useState<DropdownOption[]>([]);

  const getLabel = useCallback(
    (date: string) => {
      if (wizardState.config.datasetStep.interval === 'day') {
        return formatDateLabel(date, 'day', false, undefined, undefined, true);
      } else if (wizardState.config.datasetStep.interval === 'week') {
        return formatVerboseWeek({
          startDate: date,
          isYearImportant: true,
          isShort: false,
        });
      } else if (wizardState.config.datasetStep.interval === 'month') {
        return formatDateLabel(
          date,
          'month',
          false,
          undefined,
          undefined,
          true,
        );
      } else {
        const e = new Error(wizardState.config.datasetStep.interval);
        e.name = 'Unknown performance interval';
        throw e;
      }
    },
    [wizardState.config.datasetStep.interval],
  );

  useEffect(() => {
    const momentArg =
      env.ENV === 'Staging' || process.env.NODE_ENV === 'test'
        ? '2021-06-03'
        : wizardState.isEditing
          ? wizardState.config.datasetStep.startDate
          : undefined;
    const baseIsoDates = getDateBuckets({
      startDate: moment(momentArg).subtract({ year: 2 }).format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      interval: wizardState.config.datasetStep.interval,
      weekStartsOn: wizardState.config.datasetStep.startOfWeek || weekStartsOn,
    });

    const isoDates = (() => {
      if (!wizardState.isEditing) {
        return baseIsoDates;
      }

      // Align with current startDate
      const currentStartDate = wizardState.config.datasetStep.startDate;
      if (!currentStartDate) {
        return baseIsoDates;
      }

      const currentIndex = baseIsoDates.findIndex(
        (d) => d === currentStartDate,
      );
      if (currentIndex === -1) {
        const e = new Error();
        e.name = 'Performance Dataset editor: StartDate not found';
        throw e;
      }

      const currentIntervalLength =
        wizardState.config.datasetStep.intervalLength;
      return baseIsoDates.filter((isoDate, index) => {
        const relativeIndex = index - currentIndex;
        return relativeIndex % currentIntervalLength === 0;
      });
    })();

    setDateOptions(
      isoDates.map((date) => ({
        value: date,
        label: getLabel(date),
        isSelected: wizardState.config.datasetStep.startDate === date,
        onSelected: () => {
          setWizardState((s) => ({
            ...s,
            config: {
              ...s.config,
              datasetStep: {
                ...s.config.datasetStep,
                startDate: date,
              },
            },
          }));
        },
      })),
    );
  }, [
    getLabel,
    setWizardState,
    weekStartsOn,
    wizardState.config.datasetStep.interval,
    wizardState.config.datasetStep.intervalLength,
    wizardState.config.datasetStep.startDate,
    wizardState.config.datasetStep.startOfWeek,
    wizardState.isEditing,
  ]);

  return dateOptions;
};

export default useDateOptions;
