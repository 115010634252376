import React from 'react';

interface ExportGridContextType {
  isExportPending: boolean;
  onExportRequestStarted: (fileName: string) => void;
  onExportRequestResponse: (exportId: string) => void;
  onExportRequestFailed: () => void;
}

const ExportGridContext = React.createContext<ExportGridContextType>({
  isExportPending: false,
  onExportRequestStarted: window.tokenFunction,
  onExportRequestResponse: window.tokenFunction,
  onExportRequestFailed: window.tokenFunction,
});

export default ExportGridContext;
