import { useContext, useMemo } from 'react';
import CloudFunctionClientContext from 'contexts/CloudFunctionClientContext';
import CardContext from 'contexts/CardContext';
import ToastContext from 'contexts/ToastContext';
import useDateScope from 'hooks/useDateScope';
import useFilterInput from 'hooks/useFilterInput';
import visTypeCheckers from 'types/visTypeCheckers';
import exportGrid from '../api/exportGrid';
import { GridApi } from 'ag-grid-community';
import GridContext from 'contexts/GridContext';
import ExportGridContext from '../context/ExportGridContext';
import { DefaultDatasetFiltersContext } from '../../../contextProviders/SplashScreenProviders/DatasetProviders/DefaultDatasetFiltersProvider';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';

const GRID_MAX_DOCUMENTS_EXPORT_COUNT = 10000;

const useExportGrid = ({
  exportName,
  overrideGridApi,
}: {
  exportName?: string;
  overrideGridApi?: GridApi;
}) => {
  const { gridApi } = useContext(GridContext);
  const { chartDefinition } = useContext(CardContext);
  const { apiGateway } = useContext(CloudFunctionClientContext);
  const { showToast } = useContext(ToastContext);
  const {
    onExportRequestStarted,
    onExportRequestResponse,
    onExportRequestFailed,
  } = useContext(ExportGridContext);

  const dateScope = useDateScope({});
  const baseFilterInput = useFilterInput();
  const { defaultDatasetFiltersLookup } = useContext(
    DefaultDatasetFiltersContext,
  );
  const { isDefaultFiltersDisabled } = useContext(FilterPlatesContext);
  const dataset = useMemo(() => {
    if (
      visTypeCheckers.isV5ChartDef(chartDefinition) &&
      chartDefinition.simpleGridConfig
    ) {
      return chartDefinition.simpleGridConfig.dataType;
    }

    return undefined;
  }, [chartDefinition]);
  const filterInput = useMemo((): FilterInput[] => {
    if (!dataset) {
      return [];
    }
    const result: FilterInput[] = [baseFilterInput];
    if (!isDefaultFiltersDisabled) {
      const f = defaultDatasetFiltersLookup[dataset];
      if (f) {
        result.push(f.filterInput);
      }
    }

    return result;
  }, [
    dataset,
    baseFilterInput,
    isDefaultFiltersDisabled,
    defaultDatasetFiltersLookup,
  ]);

  const gridApiToUse = overrideGridApi || (gridApi as GridApi);

  //@ts-ignore
  const documentCount = gridApiToUse?.rowModel?.datasource?.searchResult?.count;

  const isDocumentCountExceeded = documentCount
    ? documentCount >= GRID_MAX_DOCUMENTS_EXPORT_COUNT
    : false;

  const exportSimpleGrid = async () => {
    if (!gridApi && !overrideGridApi) {
      throw new Error('Grid API missing');
    }

    if (isDocumentCountExceeded) {
      return;
    }

    const transformExportSortBy = (chartSort: SimpleGridSort[]) => {
      const result: GridSortField[] = [];

      if (chartSort) {
        chartSort.forEach((s) => {
          result.push({ field: s.field, sort: s.direction });
        });
      }

      return result;
    };

    const exportParams = (() => {
      if (
        visTypeCheckers.isV5ChartDef(chartDefinition) &&
        chartDefinition.simpleGridConfig
      ) {
        const data_type = chartDefinition.simpleGridConfig.dataType;
        const name = exportName ? exportName : data_type;
        const filters = filterInput;
        const date_scope = dateScope;
        const sort_by = transformExportSortBy(
          chartDefinition.simpleGridConfig.sort,
        );

        const include_fields = (() => {
          const { fields, fieldAliases } = chartDefinition.simpleGridConfig;

          if (!fieldAliases) {
            return fields;
          }

          return fieldAliases.map((alias) => {
            return alias.field;
          });
        })();

        return {
          name,
          data_type,
          filters,
          date_scope,
          sort_by,
          include_fields,
        };
      }
      return undefined;
    })();

    if (!exportParams) {
      throw new Error('Simple grid config is not defined');
    }

    onExportRequestStarted(exportParams.name);
    const response = await exportGrid({
      api: apiGateway,
      ...exportParams,
    });

    if (response.ok && response.data) {
      onExportRequestResponse(response.data.exportDocumentId);
    } else {
      onExportRequestFailed();
      showToast('Error exporting grid');
    }
  };

  return { exportSimpleGrid, isDocumentCountExceeded };
};

export default useExportGrid;
