import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Label from '../Label';
import Inputs from 'components/Inputs';
import useMinYTypeOptions from './useMinYTypeOptions';
import useMaxYTypeOptions from './useMaxYTypeOptions';
import Alert from 'kingpin/Alert';

const InputWrapper = styled.div`
  margin-bottom: 8px;
`;

const YAxisConfig = ({
  series,
  updateSeries,
}: {
  series: V5ChartDefinitionSeries;
  updateSeries: (s: V5ChartDefinitionSeries) => void;
}) => {
  const [minYType, setMinYType] = useState<YAxisConfigType>(
    series.minY === undefined ? 'Auto' : 'Set',
  );

  const [maxYType, setMaxYType] = useState<YAxisConfigType>(
    series.maxY === undefined ? 'Auto' : 'Set',
  );

  const onMinYValueChanged = useCallback(
    (newValue?: number) => {
      if (minYType === 'Set') {
        updateSeries({ ...series, minY: newValue });
      }
    },
    [minYType, series, updateSeries],
  );

  const onMaxYValueChanged = useCallback(
    (newValue?: number) => {
      if (maxYType === 'Set') {
        updateSeries({ ...series, maxY: newValue });
      }
    },
    [maxYType, series, updateSeries],
  );

  const onMinYTypeChanged = useCallback(
    (newType: YAxisConfigType) => {
      setMinYType(newType);
      if (newType === 'Auto' && series.minY !== undefined) {
        updateSeries({ ...series, minY: undefined });
      }
    },
    [series, updateSeries],
  );

  const onMaxYTypeChanged = useCallback(
    (newType: YAxisConfigType) => {
      setMaxYType(newType);
      if (newType === 'Auto' && series.maxY !== undefined) {
        updateSeries({ ...series, maxY: undefined });
      }
    },
    [series, updateSeries],
  );

  const minYTypeOptions = useMinYTypeOptions({ minYType, onMinYTypeChanged });
  const maxYTypeOptions = useMaxYTypeOptions({ maxYType, onMaxYTypeChanged });

  return (
    <>
      <InputWrapper>
        <Label>Min Y behaviour</Label>
        <Inputs.Dropdown options={minYTypeOptions} />
      </InputWrapper>

      {minYType === 'Set' && (
        <InputWrapper>
          <Label>Set Min Y:</Label>
          <Inputs.Number
            value={series.minY}
            setValue={onMinYValueChanged}
            placeholder="0"
          />
        </InputWrapper>
      )}

      <InputWrapper>
        <Label>Max Y behaviour</Label>
        <Inputs.Dropdown options={maxYTypeOptions} />
      </InputWrapper>

      {maxYType === 'Set' && (
        <InputWrapper>
          <Label>Set Max Y:</Label>
          <Inputs.Number
            value={series.maxY}
            setValue={onMaxYValueChanged}
            placeholder="0"
          />
        </InputWrapper>
      )}

      {series.minY !== undefined &&
        series.maxY !== undefined &&
        series.minY >= series.maxY && (
          <Alert
            title={'Invalid Axis configured'}
            body={
              'You have configured the minimum value of this axis to be greater than the maximum value. Please revise your settings to continue.'
            }
            type={'Danger'}
          />
        )}
    </>
  );
};

export default YAxisConfig;
