import React from 'react';
import { Option } from '../../../kingpin/atoms/ContextMenu';
import Button from '../../../kingpin/atoms/Button';

const ExportButton = ({
  onClick,
  id,
  isContextMenuItem,
  disabledMessage,
}: {
  onClick: () => void;
  id: string;
  isContextMenuItem?: boolean;
  disabledMessage?: string;
}) => (
  <div id={'export-grid'}>
    {isContextMenuItem && (
      <Option
        id={id}
        onClick={onClick}
        label={'Export'}
        icon={'export'}
        isDisabled={!!disabledMessage}
        disabledMessage={disabledMessage}
      />
    )}
    {!isContextMenuItem && (
      <div id={id}>
        <Button
          size={'Small'}
          type={'Primary'}
          icon={'export'}
          label="Export"
          onClick={onClick}
          isDisabled={!!disabledMessage}
          isDisabledMessage={disabledMessage}
        />
      </div>
    )}
  </div>
);

export default ExportButton;
