import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment';

import removeTodayIfItIsZero from '../../../../removeTodayIfItIsZero';
import removeFutureValues from '../../../../removeFutureValues';
import useV5ChartData from '../../../../hooks/useV5ChartData';
import timeSeriesOptions from '../../highchartOptions/timeSeriesOptions';
import AccountContext from '../../../../contexts/AccountContext';
import { ChartContent } from '../../../Chart';
import chartColors from '../../../../theme/chartColors';
import formatFloat from '../../../../api/getChartSeries/formatFloat';
import base from '../../highchartOptions/baseOptions';
import ComparisonContext from '../../../../contexts/ComparisonContext';
import useChartDrillDowns from '../../../../hooks/useChartDrillDowns';
import DashboardContextMenuContext from '../../../../contexts/DashboardContextMenuContext';
import toAutoInterval from '../../toAutoInterval';
import getGroupByField from '../../../../hooks/useMetricMatrix/getGroupByField';
import getCurrentDateTimeFor from '../../getCurrentDateTimeFor';
import buildTooltipFormatter from '../../tooltipFormatter';
import removePartialIntervals, {
  removePartialFirstInterval,
} from '../../../../removePartialIntervals';
import useDateScope from '../../../../hooks/useDateScope';
import Loading from '../../../Loading';
import FlexCentered from '../../../Common/FlexCentered';
import useValueFormatters from '../../../../hooks/useValueFormatters';
import { renderToString } from 'react-dom/server';
import isComparisonSeriesItem from 'isComparisonSeriesItem';

const isSingleMetricAcrossDimension = (chartDef: V5ChartDefinition) =>
  chartDef.dimensionB !== undefined && chartDef.series.length === 1;

const isWithoutExtraDimension = (chartDef: V5ChartDefinition) =>
  chartDef.dimensionB === undefined;

export const shouldUseComparisonTooltip = ({
  chartDef,
  comparison,
}: {
  chartDef: V5ChartDefinition;
  comparison?: PersistedComparisonType;
}) => {
  return (
    !isSingleMetricAcrossDimension(chartDef) &&
    !!comparison &&
    chartDef.series.length === 1
  );
};

// https://api.highcharts.com/class-reference/Highcharts#.TooltipFormatterCallbackFunction
const buildMultipleSeriesFormatter = (
  formatMetric: (args: { metricId: string; value: number }) => string,
  showHours?: boolean,
) => {
  return function () {
    // @ts-ignore
    const { points } = this as Highcharts.TooltipFormatterContextObject;
    if (!points || points.length === 0) {
      return '';
    }
    // @ts-ignore
    const datetime = points[0].point['datetime'] || (points[0].x as number);
    const date = moment
      .utc(datetime)
      .format(showHours ? 'YYYY-MM-DD ha' : 'YYYY-MM-DD');
    return renderToString(
      <div>
        <h3>{date}</h3>
        <br />
        {points.map((p) => {
          // @ts-ignore
          const { metricId } = p.series.userOptions;
          const value = formatMetric({ value: p.y || 0, metricId });

          return (
            <React.Fragment key={Math.random()}>
              <span
                key={p.key}
                style={{
                  color: typeof p.color === 'string' ? p.color : undefined,
                }}
              >
                ●{' '}
              </span>
              <span style={{ color: '#878F9D' }}>{p.series.name}: </span>
              <b>{value}</b>
              <br />
            </React.Fragment>
          );
        })}
      </div>,
    );
  };
};

const getSeriesFor = (
  data: V5ChartData,
  chartDef: V5ChartDefinition,
  comparisonData?: V5ChartData,
  dateRange?: DateRangeInput,
  comparison?: PersistedComparisonType,
  interval?: FleetOps.Interval,
): (HistogramSeriesItem | ComparisonSeriesItem)[] => {
  try {
    if (isSingleMetricAcrossDimension(chartDef)) {
      const dimension = chartDef.dimensionB as Dimension;
      const series = chartDef.series[0];
      return getSeriesForSingleMetricAcrossDimension(
        data,
        dimension,
        series.metricId,
        chartDef,
        interval,
      );
    }

    if (isWithoutExtraDimension(chartDef)) {
      const thisPeriod = getSeriesWithoutExtraDimension(
        data,
        chartDef,
        false,
        interval,
      );
      if (comparisonData && comparison && dateRange) {
        const comparePeriod = getSeriesWithoutExtraDimension(
          comparisonData,
          chartDef,
          true,
          interval,
        );
        const toComparisonSeries = (
          s: HistogramSeriesItem,
          index: number,
          isComparison?: boolean,
        ): ComparisonSeriesItem => {
          const data = s.data.map(([datetime, value], index) => {
            return {
              x: index,
              y: value,
              datetime,
            };
          });

          if (isComparison) {
            const thisPeriodS = thisPeriod[index];
            return {
              ...s,
              data: data.slice(0, thisPeriodS.data.length),
              colorIndex: index,
              opacity: 1,
            };
          }

          return {
            ...s,
            data,
            colorIndex: index,
            opacity: 1,
          };
        };

        return [
          ...thisPeriod.map((s, index) => toComparisonSeries(s, index)),
          ...comparePeriod.map((s, index) =>
            toComparisonSeries(s, index, true),
          ),
        ];
      } else {
        return thisPeriod;
      }
    }
  } catch (ex) {
    return [];
  }

  throw new Error('Unknown chart definition pattern');
};

const getSeriesWithoutExtraDimension = (
  data: V5ChartData,
  chartDef: V5ChartDefinition,
  isComparison?: boolean,
  interval?: FleetOps.Interval,
) => {
  return Object.values(data).map(({ name, metric, response }, index) => {
    const d = Object.values(response).map(
      (i) =>
        [new Date(i.date as string).getTime(), i[metric.id] as number] as [
          number,
          number,
        ],
    );
    const dateScope = (() => {
      try {
        let ds = undefined as DateRangeInput | undefined;
        Object.values(data).forEach((item) => {
          item.response.forEach((r) => {
            ds = r['dateScope'] as DateRangeInput | undefined;
            if (ds !== undefined) {
              return ds;
            }
          });
        });

        return ds;
      } catch (ex) {
        return undefined;
      }
    })();
    const filteredValues = chartDef.excludePartialIntervals
      ? removePartialIntervals(removeTodayIfItIsZero(d), interval, dateScope)
      : removePartialFirstInterval(
          removeTodayIfItIsZero(d),
          interval,
          dateScope,
        );
    const formattedValues = filteredValues.map(([date, v]) => [
      date,
      formatFloat(v, metric.formatting.precision),
    ]);

    return {
      name: isComparison ? `${name} - Last Period` : name,
      data: formattedValues,
      marker: {
        enabled: false,
        symbol: 'circle',
        fillColor: 'white',
        lineWidth: 1,
        lineColor: chartColors[index],
        radius: 3,
      },
      interval: chartDef.trendByCalendarInterval,
      dashStyle: isComparison ? 'Dash' : 'Solid',
      metricId: metric.id,
    } as HistogramSeriesItem;
  });
};

const getSeriesForSingleMetricAcrossDimension = (
  data: V5ChartData,
  dimension: Dimension,
  metricId: string,
  chartDef: V5ChartDefinition,
  interval?: FleetOps.Interval,
) => {
  const groupKey = dimension.field;
  const metricData = data[metricId];
  const dateScope = (() => {
    try {
      return Object.values(data)[0].response[0].dateScope as DateRangeInput;
    } catch (ex) {
      return undefined;
    }
  })();

  return Object.values(metricData.response).map(
    (dimensionSegment: any, index) => {
      const segmentName = dimensionSegment[groupKey];
      const d = Object.values(dimensionSegment.values).map(
        (i: any) =>
          [new Date(i.date).getTime(), i[metricId] as number] as [
            number,
            number,
          ],
      );

      const filteredValues = removePartialIntervals(
        removeTodayIfItIsZero(removeFutureValues(d)),
        interval,
        dateScope,
      );
      const formattedValues = filteredValues.map(([date, v]) => [
        date,
        formatFloat(v, metricData.metric.formatting.precision),
      ]);

      return {
        name: segmentName,
        data: formattedValues,
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: 'white',
          lineWidth: 1,
          lineColor: chartColors[index],
          radius: 3,
        },
        interval: chartDef.trendByCalendarInterval,
        metricId,
      } as HistogramSeriesItem;
    },
  );
};

const TimeSeriesContent = ({
  chartDefinition,
  chartRef,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
  useSmallNoDataMessage: boolean;
}) => {
  // Context
  const { unitsLocale } = useContext(AccountContext);
  const { currentComparison: comparison } = useContext(ComparisonContext);
  const { openMenu } = useContext(DashboardContextMenuContext);

  // State
  const [autoInterval, setAutoInterval] = useState<AutoInterval | undefined>();
  const groupField = getGroupByField(chartDefinition, autoInterval);
  const [options, setOptions] = useState<Highcharts.Options | undefined>();
  const [isNoDataToDisplay, setIsNoDataToDisplay] = useState<boolean>(false);

  // Hooks
  const dateRange = useDateScope({});
  const { data, comparisonData } = useV5ChartData(
    chartDefinition,
    autoInterval,
  );
  const { onDrillDown, syncChart } = useChartDrillDowns(
    groupField,
    chartRef,
    'bar',
    chartDefinition,
    autoInterval,
  );
  const { formatMetric } = useValueFormatters();

  // Effects
  useEffect(() => {
    if (!data) {
      return;
    }
    if (chartDefinition.trendByCalendarInterval !== 'auto') {
      return;
    }

    const aI: string | undefined = (() => {
      try {
        const firstItem = Object.values(data)[0].response[0];
        const maybeInterval = firstItem.values
          ? firstItem.values[0].interval
          : firstItem.interval;

        if (typeof maybeInterval === 'string') {
          return maybeInterval;
        }
      } catch (ex) {
        return undefined;
      }
      return undefined;
    })();

    const newAI = toAutoInterval(aI);
    setAutoInterval((currentAi) =>
      _.isEqual(currentAi, newAI) ? currentAi : newAI,
    );
  }, [chartDefinition.trendByCalendarInterval, data]);

  useEffect(() => {
    syncChart();
  }, [syncChart]);

  useEffect(() => {
    if (!data) {
      setIsNoDataToDisplay(false);
      return;
    }

    const interval = autoInterval
      ? autoInterval.interval
      : chartDefinition.trendByCalendarInterval;

    const series = getSeriesFor(
      data,
      chartDefinition,
      comparisonData,
      dateRange,
      comparison,
      interval,
    );

    const getSeriesDef = (chartDef: V5ChartDefinition, metricId: string) =>
      chartDef.series.find((s) => s.metricId === metricId);

    const getYAxisFor = (data: V5ChartData, chartDef: V5ChartDefinition) => {
      return Object.entries(data).map(([metricId]) => {
        const seriesDef = getSeriesDef(chartDef, metricId);

        return {
          max: seriesDef?.maxY,
          min: seriesDef?.minY,
        };
      });
    };

    const yAxis = getYAxisFor(data, chartDefinition);

    const getYAxisIndexFor = (
      s: HistogramSeriesItem | ComparisonSeriesItem,
    ): number => {
      return series
        .filter((s) => s.dashStyle !== 'Dash')
        .findIndex((item) => item.metricId === s.metricId);
    };

    const newOptions = (() => {
      if (comparison && comparisonData && dateRange) {
        return {
          ...timeSeriesOptions({
            title: ' ',
            showLegend:
              isSingleMetricAcrossDimension(chartDefinition) ||
              chartDefinition.series.length > 1,
            openMenu,
            chartDef: chartDefinition,
            fontSize: 11,
            formatMetric,
          }),
          series: series.map((s) => {
            const yAxis = ((): number => {
              if (isComparisonSeriesItem(s)) {
                const histogramSeriesItem = series.find(
                  (item) =>
                    item.metricId === s.metricId && item.name !== s.name,
                );

                if (!histogramSeriesItem) {
                  throw new Error('histogramSeriesItem is not defined');
                }

                return getYAxisIndexFor(histogramSeriesItem);
              }

              return getYAxisIndexFor(s);
            })();

            return {
              ...s,
              onDrillDown,
              animation: false,
              yAxis,
            };
          }),
          xAxis: {
            tickPixelInterval: interval === 'hour' ? 200 : 100,
            type: 'datetime',
            padding: 11,
            labels: {
              style: {
                whiteSpace: 'nowrap',
                fontSize: 11,
              },
              formatter: function () {
                // @ts-ignore
                const x = this.value as number;
                try {
                  return Highcharts.dateFormat(
                    `${interval === 'hour' ? '%m/%d %kh' : '%m/%e'}`,
                    getCurrentDateTimeFor(x, series),
                  );
                } catch (ex) {
                  console.warn(ex.message);
                  // Prevent crash on date change
                  return x;
                }
              },
            },
            gridLineWidth: 1,
            dateTimeLabelFormats: {
              // don't display the dummy year
              month: '%m/%e',
              year: '%b',
            },
          },
          yAxis: (() => {
            if (yAxis) {
              return yAxis.map((axis) => ({
                ...axis,
                startOnTick: false,
                title: { enabled: false },
                labels: {
                  style: {
                    fontSize: 11,
                  },
                },
              }));
            }

            return {
              title: { enabled: false },
              labels: {
                style: {
                  fontSize: 11,
                },
              },
            };
          })(),
          tooltip: shouldUseComparisonTooltip({
            chartDef: chartDefinition,
            comparison,
          })
            ? {
                ...base(formatMetric).tooltip,
                useHTML: true,
                formatter: buildTooltipFormatter({
                  series,
                  autoInterval,
                  data,
                  formatMetric,
                }),
              }
            : {
                ...base(formatMetric).tooltip,
                formatter: buildMultipleSeriesFormatter(formatMetric),
              },
        };
      } else {
        return {
          ...timeSeriesOptions({
            title: ' ',
            showLegend:
              isSingleMetricAcrossDimension(chartDefinition) ||
              chartDefinition.series.length > 1,
            openMenu,
            chartDef: chartDefinition,
            fontSize: 11,
            formatMetric,
          }),
          series: series.map((s) => {
            const yAxis = ((): number => {
              if (isComparisonSeriesItem(s)) {
                const histogramSeriesItem = series.find(
                  (item) =>
                    item.metricId === s.metricId && item.name !== s.name,
                );

                if (!histogramSeriesItem) {
                  throw new Error('histogramSeriesItem is not defined');
                }

                return getYAxisIndexFor(histogramSeriesItem);
              }

              return getYAxisIndexFor(s);
            })();

            return {
              ...s,
              onDrillDown,
              animation: false,
              yAxis,
            };
          }),
          xAxis: {
            type: 'datetime',
            padding: 11,
            step: Math.ceil(series[0] ? series[0].data.length / 7 : 2),
            labels: {
              style: {
                whiteSpace: 'nowrap',
                fontSize: 11,
              },
              formatter: function () {
                // @ts-ignore
                const x = this.value as number;
                try {
                  return Highcharts.dateFormat(
                    `${interval === 'hour' ? '%m/%d %kh' : '%m/%e'}`,
                    getCurrentDateTimeFor(x, series),
                  );
                } catch (ex) {
                  console.warn(ex.message);
                  // Prevent crash on date change
                  return x;
                }
              },
            },
          },
          yAxis: (() => {
            if (yAxis) {
              return yAxis.map((axis) => ({
                ...axis,
                startOnTick: false,
                title: { enabled: false },
                labels: {
                  style: {
                    fontSize: 11,
                  },
                },
              }));
            }

            return {
              title: { enabled: false },
              labels: {
                style: {
                  fontSize: 11,
                },
              },
            };
          })(),
          tooltip: {
            ...base(formatMetric).tooltip,
            formatter: buildMultipleSeriesFormatter(
              formatMetric,
              interval === 'hour',
            ),
          },
        };
      }
    })();

    setOptions(newOptions as any as Highcharts.Options);
    setIsNoDataToDisplay(
      (series.length === 1 && series[0].data.length === 0) ||
        (series.length > 1 &&
          !series.some(
            (s: HistogramSeriesItem | ComparisonSeriesItem) =>
              s.data.length > 0,
          )),
    );
  }, [
    autoInterval,
    chartDefinition,
    comparison,
    comparisonData,
    data,
    dateRange,
    formatMetric,
    onDrillDown,
    openMenu,
    unitsLocale,
  ]);

  if (!options) {
    return (
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <ChartContent
      key={`${chartDefinition.id}-${comparison}`}
      chartRef={chartRef}
      options={options}
      noDataToDisplay={isNoDataToDisplay}
      useSmallNoDataMessage={useSmallNoDataMessage}
    />
  );
};

export default TimeSeriesContent;
