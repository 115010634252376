import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import runAggregateCompoundMetricQuery from './runAggregateCompoundMetric';
import metricTypeCheckers from '../types/metricTypeCheckers';
import getQueryAlias from './getQueryAlias';
import buildAggregateCompoundMetricQuery from './buildAggregateCompoundMetricQuery';

const isMetricInput = (
  metric: MetricInput | Metrics.SpecialMetric,
): metric is MetricInput => {
  if ('type' in metric) {
    return false;
  }
  return true;
};

const aggregateMetricMatrix = async ({
  client,
  metrics,
  expressions,
  filterInput,
  dateScope,
  groupBy,
  trendByCalendarInterval,
  trendByFixedIntervalDays,
  groupByDayOfWeek,
  groupByRange,
  groupByInterval,
  groupByHour,
  chartDefId,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  metrics: (MetricInput | Metrics.SpecialMetric)[];
  expressions: Expression2[];
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  groupBy?: V5GroupBy;
  trendByCalendarInterval?: FleetOps.Interval;
  trendByFixedIntervalDays?: number;
  groupByDayOfWeek?: boolean;
  groupByRange?: V5RangeAggregation;
  groupByInterval?: V5Histogram;
  groupByHour?: boolean;
  chartDefId?: string; // for mocking purposes
}): Promise<MetricsResponse> => {
  const maybeSpecialMetric = metrics.find(metricTypeCheckers.isSpecialMetric);
  const alias = maybeSpecialMetric
    ? maybeSpecialMetric.query
    : getQueryAlias({
        groupBy,
        trendByCalendarInterval,
        trendByFixedIntervalDays,
        groupByDayOfWeek,
        groupByRange,
        groupByInterval,
      });

  const queryName = maybeSpecialMetric
    ? maybeSpecialMetric.query
    : 'aggregateCompoundMetric';
  const sanitizedMetrics = metrics.filter(isMetricInput);

  const query = buildAggregateCompoundMetricQuery({
    metrics: maybeSpecialMetric ? [] : sanitizedMetrics,
    expressions,
    filterInput,
    dateScope,
    query: queryName,
    alias,
    groupBy,
    trendByCalendarInterval,
    trendByFixedIntervalDays,
    groupByDayOfWeek,
    groupByRanges: groupByRange,
    groupByInterval,
    groupByHour,
  });

  const request = runAggregateCompoundMetricQuery({
    query,
    client,
    chartDefId,
    groupBy,
    trendByCalendarInterval,
  });
  const response = await request;
  const data = response.data[queryName] as MetricsResponse;
  return data.map((d) => ({
    ...d,
    filterInput: filterInput[0],
    dateScope,
  }));
};

export default aggregateMetricMatrix;
