import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import MetricsInput from './MetricsInput';
import DimensionsInput from './DimensionsInput';
import StackedBarOptionsInput from './StackedBarOptionsInput';
import StackedAreaOptionsInput from './StackedAreaOptionsInput';

import SimpleGridConfigInput from './SimpleGridConfigInput';
import ErrorBoundary from '../Common/ErrorBoundary';
import Actions from './Actions';
import CardContext from '../../contexts/CardContext';
import VisualisationUsageDetails from '../VisualisationUsageDetails';
import V5Gadget from '../V5Gadget';
import { Section } from '../MyAccount/Profile/styles';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../Inputs';
import toSentenceCase from '../../services/toSentenceCase';
import Label from './Label';
import DateInput from '../DateInput';
import DataLabelsInput from './DataLabelsInput';
import Row from '../Common/Row';
import GroupByLimitModeInput from './GroupByLimitModeInput';
import LimitInput from './LimitInput';
import { TOP_BAR_HEIGHT } from '../../constants';
import Colors2 from '../../theme/Colors2';
import { V5_GADGET_TYPES } from 'components/GadgetForm';

export const GadgetFormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border-radius: 3px;
`;

export const GadgetFormInner = styled.div<{ isGadgetBuilder: boolean }>`
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: ${(props) => (props.isGadgetBuilder ? '0px' : '12px 24px')};

  ${(props) => props.isGadgetBuilder && `overflow-y: auto; height: 100vh;`}

  border-right: 1px solid ${Colors2.Border};
`;

export const GadgetFormInnerTopBar = styled.div<{ isGadgetBuilder: boolean }>`
  height: ${TOP_BAR_HEIGHT}px;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  padding: ${(props) => (props.isGadgetBuilder ? '0px 12px' : '0px')};
  padding-bottom: 4px;
`;

export const GadgetFormInnerContent = styled.div<{ isGadgetBuilder: boolean }>`
  padding: ${(props) => (props.isGadgetBuilder ? '0px 12px' : '0px')};
`;

export const GadgetFormPreview = styled.div`
  flex: 2;
  flex-direction: column;
  padding: 8px;
  overflow-y: hidden;
  position: relative;
  display: flex;
  height: 100vh;
`;

export const InputWrapper = styled.div`
  margin-bottom: 8px;
`;

const V5GadgetForm = ({
  name,
  onNameChange,
  description,
  onDescriptionChange,
  gadgetType,
  onGadgetTypeChange,
  isShowingGroupingInputs,
  groupSortByOptions,
  groupByLimit,
  onGroupByLimitChange,
  isShowingTrendingInputs,
  trendingMode,
  trendingModeOptions,
  trendByCalendarInterval,
  onTrendByCalendarIntervalChange,
  excludePartialIntervals,
  onExcludePartialIntervalsChange,
  trendByFixedIntervalDays,
  onTrendByFixedIntervalChange,
  draftChart,
  isDimensionalChart,
  isValidChart,
  gridConfig,
  setGridConfig,
  isGadgetBuilder,
  isSizedToFitOverridden,
  onIsSizedToFitChange,
}: {
  name: string;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  gadgetType: GadgetType;
  onGadgetTypeChange: (newType: GadgetType) => void;
  isShowingGroupingInputs: boolean;
  groupSortByOptions: RadioOption[];
  groupByLimit?: number;
  onGroupByLimitChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isShowingTrendingInputs: boolean;
  trendingMode: 'calendarInterval' | 'fixedInterval' | 'hourInterval';
  trendingModeOptions: RadioOption[];
  trendByCalendarInterval?: FleetOps.Interval | 'auto';
  onTrendByCalendarIntervalChange: (
    newValue: FleetOps.Interval | 'auto',
  ) => void;
  excludePartialIntervals: boolean;
  onExcludePartialIntervalsChange: (newValue: boolean) => void;
  trendByFixedIntervalDays?: number;
  onTrendByFixedIntervalChange: (event: ChangeEvent<HTMLInputElement>) => void;
  draftChart?: V5ChartDefinition;
  isDimensionalChart: boolean;
  isValidChart: boolean;
  gridConfig?: SimpleGridConfig;
  setGridConfig: React.Dispatch<
    React.SetStateAction<SimpleGridConfig | undefined>
  >;
  isGadgetBuilder: boolean;
  isSizedToFitOverridden?: boolean;
  onIsSizedToFitChange: (newValue: boolean) => void;
}) => (
  <GadgetFormWrapper>
    <GadgetFormInner isGadgetBuilder={isGadgetBuilder}>
      <GadgetFormInnerTopBar isGadgetBuilder={isGadgetBuilder}>
        <Typography.Header type="H4">Gadget Settings</Typography.Header>
      </GadgetFormInnerTopBar>
      <GadgetFormInnerContent isGadgetBuilder={isGadgetBuilder}>
        <Section>
          <Label>Name</Label>
          <Inputs.TextInput
            placeholder="Name"
            value={name}
            onChange={onNameChange}
            inputSize="Small"
            width="100%"
          />
        </Section>
        <Section>
          <Label>Description</Label>
          <Inputs.TextInput
            placeholder="Description"
            value={description}
            onChange={onDescriptionChange}
            inputSize="Small"
            width="100%"
          />
        </Section>
        <Section>
          <Label>Gadget Type</Label>
          <Inputs.Dropdown
            options={V5_GADGET_TYPES.map((gt) => ({
              label: toSentenceCase(gt),
              onSelected: () => {
                onGadgetTypeChange(gt as GadgetType);
              },
            }))}
            selectedLabel={toSentenceCase(gadgetType)}
          />
        </Section>
        <DataLabelsInput />
        <StackedBarOptionsInput />
        <Section>
          <StackedAreaOptionsInput />
        </Section>
        {gadgetType !== 'simpleGrid' && (
          <Section>
            <Typography.Header type="H5">Metrics</Typography.Header>
            <MetricsInput />
          </Section>
        )}

        {gadgetType === 'simpleGrid' && !!gridConfig && (
          <SimpleGridConfigInput
            gridConfig={gridConfig}
            setGridConfig={setGridConfig}
          />
        )}
        {isDimensionalChart && (
          <Section>
            <Typography.Header type="H5">Dimensions</Typography.Header>
            <ErrorBoundary>
              <DimensionsInput />
            </ErrorBoundary>
          </Section>
        )}
        {isShowingGroupingInputs && (
          <Section>
            <Typography.Header type="H5">Grouping</Typography.Header>
            <div style={{ padding: 12, backgroundColor: Colors2.Grey['9'] }}>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  <Typography.Body type="Label">Sort by</Typography.Body>
                </div>
                <Inputs.Radio options={groupSortByOptions} />
              </div>
              <GroupByLimitModeInput />
              <LimitInput
                groupByLimit={groupByLimit}
                onGroupByLimitChange={onGroupByLimitChange}
              />
            </div>
          </Section>
        )}
        {isShowingTrendingInputs && (
          <Section>
            <Typography.Header type="H5">Trending</Typography.Header>
            <div style={{ padding: 12, backgroundColor: Colors2.Grey['9'] }}>
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 4 }}>
                  <Typography.Body type="Label">Mode</Typography.Body>
                </div>
                <Inputs.Radio options={trendingModeOptions} />
              </div>
              <React.Fragment>
                {trendingMode === 'calendarInterval' && (
                  <React.Fragment>
                    <InputWrapper>
                      <Label>Interval</Label>
                      <Inputs.Dropdown
                        selectedLabel={trendByCalendarInterval}
                        options={[
                          'auto',
                          'year',
                          'quarter',
                          'month',
                          'week',
                          'day',
                          'hour',
                        ].map((o) => ({
                          label: o,
                          onSelected: () =>
                            onTrendByCalendarIntervalChange(
                              o as FleetOps.Interval | 'auto',
                            ),
                        }))}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Exclude partial intervals</Label>
                      <Inputs.Toggle
                        value={excludePartialIntervals}
                        onChange={onExcludePartialIntervalsChange}
                      />
                    </InputWrapper>
                  </React.Fragment>
                )}
                <InputWrapper>
                  <Label>Is sized to fit</Label>
                  <Inputs.Toggle
                    value={!!isSizedToFitOverridden}
                    onChange={onIsSizedToFitChange}
                  />
                </InputWrapper>
                {trendingMode === 'fixedInterval' && (
                  <InputWrapper>
                    <Label>Days</Label>
                    <Inputs.TextInput
                      type="number"
                      value={
                        trendByFixedIntervalDays
                          ? trendByFixedIntervalDays.toString(10)
                          : ''
                      }
                      onChange={onTrendByFixedIntervalChange}
                    />
                  </InputWrapper>
                )}
              </React.Fragment>
            </div>
          </Section>
        )}
        {isGadgetBuilder && <Actions />}
      </GadgetFormInnerContent>
    </GadgetFormInner>
    {isGadgetBuilder && (
      <>
        <GadgetFormPreview>
          <Row spaceBetween>
            <DateInput />
            {draftChart && (
              <VisualisationUsageDetails chatDefinition={draftChart} />
            )}
          </Row>
          {draftChart && (
            <>
              {(draftChart.series.length > 0 ||
                (!!draftChart.simpleGridConfig &&
                  draftChart.simpleGridConfig.fields.length > 0)) &&
                isValidChart && (
                  <ErrorBoundary>
                    <CardContext.Provider
                      // @ts-ignore
                      value={{ chartDefinition: draftChart }}
                    >
                      <V5Gadget
                        chartDefinition={draftChart}
                        key={
                          draftChart.gadgetType === 'matrix'
                            ? JSON.stringify(draftChart)
                            : undefined
                        } // Ensure fresh
                      />
                    </CardContext.Provider>
                  </ErrorBoundary>
                )}
            </>
          )}
        </GadgetFormPreview>
      </>
    )}
  </GadgetFormWrapper>
);

export default V5GadgetForm;
