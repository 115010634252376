import { useContext } from 'react';
import CardContext from '../contexts/CardContext';
import isV5ChartDef from '../types/visTypeCheckers/isV5ChartDef';

const useIsGridLinksDisabled = () => {
  const { chartDefinition } = useContext(CardContext);

  if (chartDefinition && isV5ChartDef(chartDefinition)) {
    if (
      chartDefinition.groupByDayOfWeek ||
      chartDefinition.trendByHourInterval
    ) {
      return true;
    }

    if (
      chartDefinition.simpleGridConfig &&
      chartDefinition.simpleGridConfig.disableLinks
    ) {
      return true;
    }
  }

  return false;
};

export default useIsGridLinksDisabled;
