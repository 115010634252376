import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import FilterPlatesContext from '../contexts/FilterPlatesContext';
import buildFilterInput from '../utils/buildFilterInput';
import GridContext from '../contexts/GridContext';
import mergeFilterInputs from '../mergeFilterInputs';
import FilterPlateContext from '../contexts/FilterPlateContext';
import CardContext from '../contexts/CardContext';
import visTypeCheckers from '../types/visTypeCheckers';
import EntityDetailsContext from '../screens/EntityDetailsShow/EntityDetailsContext';
import DashboardGadgetContext from '../contexts/DashboardGadgetContext';
import PopupContext from '../contexts/PopupContext';
import ReportContext from '../contexts/ReportContext';
import BoardContext from '../contexts/BoardContext';
import isPerformanceBoard from '../isPerformanceBoard';

export const usePopupEntityFilter = () => {
  const entityDetails = useContext(EntityDetailsContext);

  const {
    isOpen: isPopupOpen,
    selectedReport: popupReport,
    selectedBoard: popupBoard,
    dashboardGadget: popupFromGadget,
  } = useContext(PopupContext);

  const getPopupEntityFilter = useCallback(() => {
    if (entityDetails && entityDetails.entityFilter) {
      if (
        (popupReport || popupBoard) &&
        isPopupOpen &&
        popupFromGadget &&
        popupFromGadget.isEntityFilterEnabled
      ) {
        // If there is a popup which was triggered by a gadget with the
        // entity filter enabled
        return entityDetails.entityFilter;
      }
    }

    return undefined;
  }, [entityDetails, isPopupOpen, popupBoard, popupFromGadget, popupReport]);

  const [popupEntityFilter, setPopupEntityFilter] = useState<
    FilterInput | undefined
  >(getPopupEntityFilter());

  useEffect(() => {
    setPopupEntityFilter(getPopupEntityFilter());
  }, [getPopupEntityFilter]);

  return popupEntityFilter;
};

/**
 * This is a central point for our visualisations to control filtering
 */
const useFilterInput = (field?: string, dataType?: string[]): FilterInput => {
  const entityDetails = useContext(EntityDetailsContext);
  const { drillDownsAsFixed, scopeAsFixed } = useContext(FilterPlatesContext);
  const { metricFiltering: popupGridFilter } = useContext(GridContext);
  const { chartDefinition } = useContext(CardContext);
  const { isOpen: isDrillDownOpen } = useContext(FilterPlateContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { report } = useContext(ReportContext);
  const popupEntityFilter = usePopupEntityFilter();
  const { selectedReport: popupReport } = useContext(PopupContext);
  const { board } = useContext(BoardContext);

  const getChartDefDimensionField = useCallback(() => {
    if (!chartDefinition) {
      return undefined;
    }

    if (visTypeCheckers.isV5ChartDef(chartDefinition)) {
      return chartDefinition.dimensionA && chartDefinition.dimensionA.field;
    }

    if (visTypeCheckers.isGauge(chartDefinition)) {
      return chartDefinition.peerGroup;
    }

    if (visTypeCheckers.isRankingMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    if (visTypeCheckers.isSingleMetricDateMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    return undefined;
  }, [chartDefinition]);

  const getIsIgnoringLastDrillDown = useCallback(() => {
    // Ignore the last drill-down for a dashboard/report gadget
    // if it is filtering against how the data is grouped
    if (drillDownsAsFixed.length === 0 || chartDefinition === undefined) {
      return false;
    }

    const lastDrillField =
      drillDownsAsFixed[drillDownsAsFixed.length - 1].field;
    const dimensionField = getChartDefDimensionField();

    if (
      !!lastDrillField &&
      !!dimensionField &&
      lastDrillField === dimensionField
    ) {
      return true;
    }
  }, [chartDefinition, getChartDefDimensionField, drillDownsAsFixed]);

  const getScopes = useCallback(() => {
    if (isDrillDownOpen) {
      return [];
    } else {
      return scopeAsFixed;
    }
  }, [isDrillDownOpen, scopeAsFixed]);
  const getDrillDowns = useCallback(() => {
    if (getIsIgnoringLastDrillDown()) {
      return drillDownsAsFixed.slice(0, drillDownsAsFixed.length - 1);
    } else if (isDrillDownOpen) {
      return drillDownsAsFixed.filter((d) => d.field !== field);
    } else {
      return drillDownsAsFixed;
    }
  }, [isDrillDownOpen, field, getIsIgnoringLastDrillDown, drillDownsAsFixed]);

  const drillDownAndScopeFi = useMemo((): FilterInput => {
    const scopes = getScopes();
    const drillDowns = getDrillDowns();
    return buildFilterInput({
      scopes,
      drillDowns,
      dataType,
    });
  }, [dataType, getDrillDowns, getScopes]);

  const entityFi = useMemo((): FilterInput | undefined => {
    const isEntityFilteredDashboardGadget =
      dashboardGadget && dashboardGadget.isEntityFilterEnabled;
    const isEntityFilteredReport =
      report && report.isEntityFilterEnabled && popupReport === undefined;
    const isEntityFilteredBoard =
      board && isPerformanceBoard(board) && board.isEntityFilterEnabled;

    const isEntityFilteredContent =
      isEntityFilteredDashboardGadget ||
      isEntityFilteredReport ||
      isEntityFilteredBoard;

    if (
      entityDetails &&
      entityDetails.entityFilter &&
      isEntityFilteredContent
    ) {
      return entityDetails.entityFilter;
    }

    return undefined;
  }, [board, dashboardGadget, entityDetails, popupReport, report]);

  /**
   * Note that there is additional filtering which occurs on the metric
   * level
   *
   * {@link useToMetricInput}
   */
  const getFilterInput = useCallback(() => {
    const filtersToMerge: FilterInput[] = [];

    if (entityFi) {
      filtersToMerge.push(entityFi);
    }

    if (popupGridFilter) {
      filtersToMerge.push(popupGridFilter);
    }

    if (popupEntityFilter) {
      // If there is a popup which was triggered by a gadget with the
      // entity filter enabled
      filtersToMerge.push(popupEntityFilter);
    }

    return filtersToMerge.reduce(
      (a, b) => {
        return mergeFilterInputs(a, b);
      },
      { ...drillDownAndScopeFi },
    );
  }, [drillDownAndScopeFi, entityFi, popupGridFilter, popupEntityFilter]);

  const [filterInput, setFilterInput] = useState<FilterInput>(() =>
    getFilterInput(),
  );

  useEffect(() => {
    setFilterInput((currentFilter) => {
      const newFilter = getFilterInput();
      if (_.isEqual(currentFilter, newFilter)) {
        return currentFilter;
      }

      return newFilter;
    });
  }, [getFilterInput]);

  return filterInput;
};

export default useFilterInput;
