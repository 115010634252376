import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import appRoutes from './appRoutes';
import OnboardingProvider from './OnboardingProvider';
import FleetOpsSuspense from '../FleetOpsSuspense';
const OnBoardingLanding = lazy(() => import('../screens/OnBoarding/Landing'));
const OnBoardingBilling = lazy(() => import('../screens/OnBoarding/Billing'));
const ConnectionView = lazy(
  () => import('../screens/Settings/Integrations/ConnectionView'),
);
const IRGuideScreen = lazy(
  () => import('../screens/Settings/Integrations/IRGuide'),
);

const RedirectToOnBoardingLanding = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(appRoutes.onBoarding.landing);
  }, [navigate]);

  return null;
};

const OnBoardingSwitch = () => (
  <OnboardingProvider>
    <FleetOpsSuspense>
      <Routes>
        <Route
          path={appRoutes.onBoarding.billing}
          element={<OnBoardingBilling />}
        />
        <Route
          path={appRoutes.onBoarding.provider}
          element={<ConnectionView />}
        />
        <Route
          path={appRoutes.onBoarding.irGuide}
          element={<IRGuideScreen />}
        />
        <Route
          path={appRoutes.onBoarding.landing}
          element={<OnBoardingLanding />}
        />
        <Route path="*" element={<RedirectToOnBoardingLanding />} />
      </Routes>
    </FleetOpsSuspense>
  </OnboardingProvider>
);

export default OnBoardingSwitch;
