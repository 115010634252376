import { useEffect, useState } from 'react';

const useMaxYTypeOptions = ({
  maxYType,
  onMaxYTypeChanged,
}: {
  maxYType: YAxisConfigType;
  onMaxYTypeChanged: (newType: YAxisConfigType) => void;
}) => {
  const [maxYTypeOptions, setMaxYTypeOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const yAxisTypes: YAxisConfigType[] = ['Auto', 'Set'];
    setMaxYTypeOptions(
      yAxisTypes.map((type) => ({
        label: type,
        value: type,
        isSelected: maxYType === type,
        onSelected: () => onMaxYTypeChanged(type),
      })),
    );
  }, [maxYType, onMaxYTypeChanged]);

  return maxYTypeOptions;
};

export default useMaxYTypeOptions;
