import { useEffect, useState } from 'react';

const useMinYTypeOptions = ({
  minYType,
  onMinYTypeChanged,
}: {
  minYType: YAxisConfigType;
  onMinYTypeChanged: (newType: YAxisConfigType) => void;
}) => {
  const [minYTypeOptions, setMinYTypeOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const yAxisTypes: YAxisConfigType[] = ['Auto', 'Set'];
    setMinYTypeOptions(
      yAxisTypes.map((type) => ({
        label: type,
        value: type,
        isSelected: minYType === type,
        onSelected: () => onMinYTypeChanged(type),
      })),
    );
  }, [minYType, onMinYTypeChanged]);

  return minYTypeOptions;
};

export default useMinYTypeOptions;
