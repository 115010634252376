import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import AccountContext from '../../../contexts/AccountContext';
import DashboardContextMenuContext from '../../../contexts/DashboardContextMenuContext';
import PopupContext from '../../../contexts/PopupContext';
import getGroupByField from '../../../hooks/useMetricMatrix/getGroupByField';
import useDateScope from '../../../hooks/useDateScope';
import useV5ChartData from '../../../hooks/useV5ChartData';
import useChartDrillDowns from '../../../hooks/useChartDrillDowns';
import toAutoInterval from '../toAutoInterval';
import toBarSeries from '../toBarSeries';
import horizontalBar from '../highchartOptions/horizontalBarOptions';
import barOptions from '../highchartOptions/barOptions';
import Loading from '../../Loading';
import { ChartContent } from '../../Chart';
import WeekStartsOnOverrideContext from '../../../contexts/WeekStartsOnOverrideContext';
import useValueFormatters from '../../../hooks/useValueFormatters';

const BarContent = ({
  chartDefinition,
  chartRef,
  isHorizontal,
  isCombo,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
  isHorizontal?: boolean;
  isCombo?: boolean;
  useSmallNoDataMessage: boolean;
}) => {
  // Context
  const { unitsLocale, weekStartsOn } = useContext(AccountContext);
  const { weekStartsOnOverride } = useContext(WeekStartsOnOverrideContext);
  const { openMenu } = useContext(DashboardContextMenuContext);
  const { isOpen: isPopupReportOpen } = useContext(PopupContext);
  const { isOpen: isPopupGridOpen } = useContext(DashboardContextMenuContext);

  // State
  const [autoInterval, setAutoInterval] = useState<AutoInterval | undefined>();
  const [options, setOptions] = useState<Highcharts.Options | undefined>();
  const [isNoDataToDisplay, setIsNoDataToDisplay] = useState<boolean>(false);
  const [groupField] = useState(getGroupByField(chartDefinition));

  // Hooks
  const { formatMetric } = useValueFormatters();
  const dateScope = useDateScope({});
  const { data } = useV5ChartData(chartDefinition, autoInterval);
  const { onDrillDown, syncChart } = useChartDrillDowns(
    groupField,
    chartRef,
    'bar',
    chartDefinition,
    autoInterval,
  );

  // Effects
  useEffect(() => {
    if (!data) {
      return;
    }
    if (chartDefinition.trendByCalendarInterval !== 'auto') {
      return;
    }
    try {
      const aI = Object.values(data)[0].response[0].interval as string;
      const newAI = toAutoInterval(aI);
      setAutoInterval((currentAi) =>
        _.isEqual(currentAi, newAI) ? currentAi : newAI,
      );
    } catch (ex) {
      return undefined;
    }
  }, [chartDefinition.trendByCalendarInterval, data]);

  useEffect(() => {
    if (!isPopupReportOpen) {
      if (chartRef.current) {
        chartRef.current.chart.getSelectedPoints().forEach((p: any) => {
          p.select();
        });
      }
    }
  }, [chartRef, isPopupReportOpen]);

  useEffect(() => {
    if (!isPopupGridOpen) {
      if (chartRef.current) {
        chartRef.current.chart.getSelectedPoints().forEach((p: any) => {
          p.select();
        });
      }
    }
  }, [chartRef, isPopupGridOpen]);

  useEffect(() => {
    syncChart();
  }, [syncChart, onDrillDown, data]);

  useEffect(() => {
    if (!data) {
      setIsNoDataToDisplay(false);
      return;
    }

    const { series, xAxis, yAxis, tooltip } = toBarSeries(
      data,
      chartDefinition,
      !!isCombo,
      unitsLocale,
      dateScope,
      11,
      weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
      formatMetric,
      autoInterval,
    );

    const isHistogram =
      !!chartDefinition.dimensionA &&
      chartDefinition.dimensionA.fieldType === 'date';
    const optionBuilder = isHorizontal ? horizontalBar : barOptions;
    const step = isHistogram
      ? Math.ceil(series[0] ? series[0].data.length / 7 : 2)
      : undefined;
    const baseBarOptions = optionBuilder({
      title: ' ',
      showLegend: series.length > 1,
      openMenu,
      groupField,
      fieldType: chartDefinition.dimensionA
        ? chartDefinition.dimensionA.fieldType
        : undefined,
      chartDef: chartDefinition,
      showDataLabels: chartDefinition.showDataLabels,
      fontSize: 11,
      isHistogram,
      formatMetric,
    });

    const newOptions = {
      ...baseBarOptions,
      series: series.map((s) => {
        return {
          ...s,
          onDrillDown,
          animation: false,
          step: s.type === 'line' ? undefined : step,
        };
      }),
      tooltip: tooltip ? tooltip : baseBarOptions.tooltip,
      xAxis: {
        ...xAxis,
        padding: 11,
        step,
      },
      yAxis: (() => {
        if (yAxis) {
          return yAxis.map((axis) => ({
            ...axis,
            startOnTick: false,
            title: { enabled: false },
            padding: 10,
            labels: {
              ...axis.labels,
              style: {
                fontSize: 11,
              },
            },
          }));
        }

        return {
          title: { enabled: false },
          padding: 10,
          labels: {
            style: {
              fontSize: 11,
            },
          },
        };
      })(),
    };

    if (newOptions.tooltip === undefined) {
      delete newOptions['tooltip'];
    }

    setOptions(newOptions as any as Highcharts.Options);
    setIsNoDataToDisplay(
      (series.length === 1 && series[0].data.length === 0) ||
        (series.length > 1 && !series.some((s) => s.data.length > 0)) ||
        (!!yAxis && yAxis.length === 0),
    );
  }, [
    autoInterval,
    chartDefinition,
    data,
    dateScope,
    formatMetric,
    groupField,
    isCombo,
    isHorizontal,
    onDrillDown,
    openMenu,
    unitsLocale,
    weekStartsOn,
    weekStartsOnOverride,
  ]);

  if (!options) {
    return <Loading />;
  }

  return (
    <ChartContent
      chartRef={chartRef}
      options={options}
      noDataToDisplay={isNoDataToDisplay}
      useSmallNoDataMessage={useSmallNoDataMessage}
    />
  );
};

export default BarContent;
