import React from 'react';

import VersionProvider from '../VersionProvider';
import UserAndAccountProviders from './UserAndAccountProviders';
import DatasetProviders from './DatasetProviders';
import ContentProviders from './ContentProviders';
import ContentDependantProviders from './ContentDependantProviders';
import LegacyDriverBonusProviders from './LegacyDriverBonusProviders';
import MiscProviders from './MiscProviders';

const SplashScreenProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <VersionProvider>
      <UserAndAccountProviders>
        <DatasetProviders>
          <ContentProviders>
            <ContentDependantProviders>
              <LegacyDriverBonusProviders>
                <MiscProviders>{children}</MiscProviders>
              </LegacyDriverBonusProviders>
            </ContentDependantProviders>
          </ContentProviders>
        </DatasetProviders>
      </UserAndAccountProviders>
    </VersionProvider>
  );
};

export default SplashScreenProviders;
