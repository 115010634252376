import React, { useCallback, useContext, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import Report from './Report';
import updateReport from '../../api/updateReport';
import { useNavigate } from 'react-router-dom';
import appRoutes, { buildReportShow } from '../../navigation/appRoutes';
import aguid from 'aguid';

import deleteReport from '../../api/deleteReport';
import withoutNulls from '../../api/expression/withoutNulls';
import ReportContext from 'contexts/ReportContext';
import ReportDateDrillDownOptionsProvider from '../../contextProviders/ReportDateDrillDownOptionsProvider';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import AccountContext from '../../contexts/AccountContext';
import shareExportedReport from '../../api/shareExportedReport';
import captureException from '../../services/captureException';
import ComparisonContext from '../../contexts/ComparisonContext';
import createDashboardGadget from '../../api/createDashboardGadget';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import BonusPeriodsContext from '../../contexts/BonusPeriodsContext';
import { toDateRange } from '../../contexts/BoardContext';
import { DateTime } from 'luxon';
import useContentViewTracking from '../../hooks/useContentViewTracking';
import updateRecentName from '../../api/recents/updateRecentName';
import updateFavouriteName from '../../api/favourites/updateFavouriteName';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';
import useDataTypesOnReportCanvas from './useDataTypesOnReportCanvas';
import useLegacySavedFilter from '../../hooks/useLegacySavedFilter';
import delay from '../../delay';
import migrateRelativeDateRange from '../../migrateRelativeDateRange';
import CopyGadgetProvider from '../../contextProviders/CopyGadgetProvider';
import PopupContext from '../../contexts/PopupContext';
import DateInputContext from '../../contexts/DateInputContext';
import { parseSavedDateRange } from '../ConfigureDashboardGadget';
import withDateFilter from '../../hocs/withDateFIlter';
import useDateScope from '../../hooks/useDateScope';
import useWindowSize from '../../hooks/useWindowSize';
import PopupInnerContext from '../../contexts/PopupInnerContext';
import WallboardContext from '../../contexts/WallboardContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import GqlClientProvider from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/GqlClientProvider';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import FilterPlatesProvider from '../../contextProviders/FilterPlatesProvider';
import WeekStartsOnOverrideContext from 'contexts/WeekStartsOnOverrideContext';
import RolesContext from '../../contexts/RolesContext';
import useHasAccess from 'hooks/useHasAccess';
import TargetsAppContext from '../../screens/TargetsAppShow/TargetsAppConfigured/TargetsAppContext';
import EntityDetailsContext from '../../screens/EntityDetailsShow/EntityDetailsContext';
import filterTypeCheckers from '../FilterPlateForm/filterTypeCheckers';
import useToFixedFilters from '../../hooks/useToFixedFilters';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import STORE from '../../store';
import useAddNewReportToCurrentPortal from '../../hooks/useAddNewReportToCurrentPortal';
import useRefreshDatasetFreshnessOnMount from '../../hooks/useRefreshDatasetFreshnessOnMount';

const getSavedFilterIdParam = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('savedFilterId')) {
    return urlParams.get('savedFilterId') as string;
  }
  return undefined;
};

const hasEditingSearchQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEditing =
    urlParams.has('editing') && urlParams.get('editing') === 'true';
  return isEditing;
};

const useInitialDateFilters = (
  report: ReportType,
  unSavedFilter?: UnSavedFilter,
  parentDashboardGadget?: DashboardGadget,
) => {
  const {
    setDateField,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
  } = useContext(DateInputContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { workSpace } = useContext(WorkSpaceContext);

  // Initial Date Range
  useEffect(() => {
    const initialDateRange = (() => {
      if (
        selectedBonusPeriod &&
        workSpace &&
        workSpace.campaignType === 'driverBonus'
      ) {
        const dateRange = selectedBonusPeriod
          ? toDateRange(selectedBonusPeriod)
          : undefined;
        if (dateRange) {
          return dateRange;
        }
      }

      if (unSavedFilter) {
        if (
          unSavedFilter.relativeDateRange ||
          unSavedFilter.advancedRelativeDateRange ||
          unSavedFilter.dateRange
        ) {
          return unSavedFilter.dateRange;
        } else {
          return unSavedFilter.dateScope;
        }
      } else if (parentDashboardGadget) {
        if (parentDashboardGadget.dateRange) {
          return parseSavedDateRange(parentDashboardGadget.dateRange);
        } else {
          return undefined;
        }
      } else {
        return report.dateRange;
      }
    })();

    setDateRange(initialDateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial Relative Date Range
  useEffect(() => {
    const initialRelativeDateRange = (() => {
      if (unSavedFilter) {
        return unSavedFilter.relativeDateRange;
      } else if (parentDashboardGadget) {
        return parentDashboardGadget.relativeDateRange
          ? migrateRelativeDateRange(parentDashboardGadget.relativeDateRange)
          : undefined;
      } else {
        return report.relativeDateRange;
      }
    })();

    setRelativeDateRange(initialRelativeDateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial Advanced Relative Date Range
  useEffect(() => {
    const initialAdvancedRelativeDateRange = (() => {
      if (unSavedFilter) {
        return unSavedFilter.advancedRelativeDateRange;
      } else if (parentDashboardGadget) {
        return parentDashboardGadget.advancedRelativeDateRange;
      } else {
        return report.advancedRelativeDateRange;
      }
    })();

    setAdvancedRelativeDateRange(initialAdvancedRelativeDateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial Date Field
  useEffect(() => {
    const initialDateField = (() => {
      if (parentDashboardGadget && parentDashboardGadget.dateField) {
        return parentDashboardGadget.dateField;
      } else if (report.dateField) {
        return report.dateField;
      } else if (unSavedFilter) {
        return unSavedFilter.dateField;
      } else {
        return 'date';
      }
    })();

    setDateField(initialDateField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useReportDateSync = (
  report: ReportType,
  unSavedFilter?: UnSavedFilter,
  parentDashboardGadget?: DashboardGadget,
) => {
  const {
    setDateField,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    isManuallyEntered,
  } = useContext(DateInputContext);
  const savedFilterId = getSavedFilterIdParam();
  const { savedFilter } = useLegacySavedFilter(savedFilterId);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { workSpace } = useContext(WorkSpaceContext);

  useEffect(() => {
    if (isManuallyEntered) {
      return;
    }

    const bonusDateRange =
      selectedBonusPeriod &&
      workSpace &&
      workSpace.campaignType === 'driverBonus'
        ? toDateRange(selectedBonusPeriod)
        : undefined;

    if (bonusDateRange) {
      setDateRange(bonusDateRange);
      setRelativeDateRange(undefined);
      setAdvancedRelativeDateRange(undefined);
    } else if (savedFilter) {
      setDateRange(savedFilter.dateRange);
      setRelativeDateRange(savedFilter.relativeDateRange);
      setAdvancedRelativeDateRange(savedFilter.advancedRelativeDateRange);
      setDateField(savedFilter.dateField);
    } else if (unSavedFilter) {
      if (
        unSavedFilter.relativeDateRange ||
        unSavedFilter.advancedRelativeDateRange ||
        unSavedFilter.dateRange
      ) {
        setDateRange(unSavedFilter.dateRange);
        setRelativeDateRange(unSavedFilter.relativeDateRange);
        setAdvancedRelativeDateRange(unSavedFilter.advancedRelativeDateRange);
      } else {
        setDateRange(unSavedFilter.dateScope);
        setRelativeDateRange(undefined);
        setAdvancedRelativeDateRange(undefined);
      }

      setDateField(unSavedFilter.dateField);
    } else if (parentDashboardGadget) {
      setDateField(parentDashboardGadget.dateField);
      setDateRange(
        parentDashboardGadget.dateRange
          ? parseSavedDateRange(parentDashboardGadget.dateRange)
          : undefined,
      );
      setRelativeDateRange(
        parentDashboardGadget.relativeDateRange
          ? migrateRelativeDateRange(parentDashboardGadget.relativeDateRange)
          : undefined,
      );
      setAdvancedRelativeDateRange(
        parentDashboardGadget.advancedRelativeDateRange,
      );
    } else {
      setDateField(report.dateField);
      setDateRange(report.dateRange);
      setRelativeDateRange(report.relativeDateRange);
      setAdvancedRelativeDateRange(report.advancedRelativeDateRange);
    }
  }, [
    isManuallyEntered,
    unSavedFilter,
    selectedBonusPeriod,
    workSpace,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    report.dateRange,
    report.relativeDateRange,
    report.advancedRelativeDateRange,
    savedFilter,
    parentDashboardGadget,
    setDateField,
    report.dateField,
  ]);
};

const ReportContainer = ({
  report,
  hideMenu,
  parentDashboardGadget,
  unSavedFilter,
  onDeleted,
  setReport,
  isValidationReport,
}: {
  report: ReportType;
  savedFilter?: LegacySavedFilter;
  hideMenu?: boolean;
  parentDashboardGadget?: DashboardGadget;
  unSavedFilter?: UnSavedFilter;
  onDeleted?: () => void;
  isPopup?: boolean;
  setReport?: React.Dispatch<React.SetStateAction<ReportType | undefined>>;
  isValidationReport?: boolean;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const navigate = useNavigate();
  const { api } = useContext(CloudFunctionClientContext);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { isPopup } = useContext(PopupInnerContext);
  const { scope, canvas, drillDowns, access } = report;
  useContentViewTracking({
    type: 'report',
    typeId: report.id,
    name: report.name,
  });
  useRefreshDatasetFreshnessOnMount();

  const { isOpen: isPopupReportOpen, origin: popupOrigin } =
    useContext(PopupContext);
  const targetsAppContext = useContext(TargetsAppContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { isWallboard } = useContext(WallboardContext);
  const { currentPermissions } = useContext(RolesContext);
  const entityDetailsContext = useContext(EntityDetailsContext);
  const savedFilterId = getSavedFilterIdParam();
  const { savedFilter } = useLegacySavedFilter(savedFilterId);
  const { isMobile } = useWindowSize();
  const addNewReportToCurrentPortal = useAddNewReportToCurrentPortal();

  const {
    dateField,
    dateRange,
    relativeDateRange,
    advancedRelativeDateRange,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
  } = useContext(DateInputContext);
  useInitialDateFilters(report, unSavedFilter, parentDashboardGadget);
  useReportDateSync(report, unSavedFilter, parentDashboardGadget);
  const dateScopeForEmail = useDateScope({});
  const { id: accountId, weekStartsOn } = useContext(AccountContext);
  useEffect(() => {}, []);
  const { trackEvent } = useContext(AnalyticsContext);
  const toDrillDowns = useToFixedFilters();

  const [currentCanvas, setCurrentCanvas] = useState<Canvas>(() => canvas);
  const dataTypes = useDataTypesOnReportCanvas(currentCanvas);
  const { setDataTypes } = useContext(DateInputContext);
  const [currentScope, setCurrentScope] = useState<FilterPlateType[]>(() => {
    if (unSavedFilter) {
      return unSavedFilter.scope;
    } else if (parentDashboardGadget) {
      return parentDashboardGadget.scope;
    } else {
      return scope;
    }
  });
  const [currentDrillDowns, setCurrentDrillDowns] = useState<FilterPlateType[]>(
    () => {
      if (unSavedFilter) {
        return unSavedFilter.drillDowns;
      } else if (parentDashboardGadget) {
        return parentDashboardGadget.drillDowns;
      } else if (drillDowns) {
        return drillDowns;
      } else {
        return [];
      }
    },
  );
  const [isEntityFilterEnabled, setIsEntityFilterEnabled] = useState<boolean>(
    () => {
      if (report) {
        if (report.isEntityFilterEnabled === undefined) {
          return false;
        }
        return report.isEntityFilterEnabled;
      }
      return false;
    },
  );
  const [isDefaultFiltersDisabled, setIsDefaultFiltersDisabled] =
    useState<boolean>(() => {
      if (parentDashboardGadget) {
        return !!parentDashboardGadget.isDefaultFiltersDisabled;
      }

      return !!report.isDefaultFiltersDisabled;
    });
  const [isEditing, setIsEditing] = useState<boolean>(hasEditingSearchQuery());
  const [hasTracked, setHasTracked] = useState<boolean>(false);
  const [hasLeftDefaultState, setHasLeftDefaultState] =
    useState<boolean>(false);
  const [currentComparison, setCurrentComparison] = useState<
    PersistedComparisonType | undefined
  >(() => {
    if (unSavedFilter) {
      return unSavedFilter.comparison;
    } else if (parentDashboardGadget) {
      return parentDashboardGadget.comparison;
    } else {
      return report.comparison;
    }
  });
  const [weekStartsOnOverride, setWeekStartsOnOverride] = useState<
    WeekStartsOn | undefined
  >(report.weekStartsOnOverride);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [gridExportCallbacks, setGridExportCallbacks] = useState<
    GridExportCallback[]
  >([]);
  const currentUser = useContext(CurrentUserContext);
  const currentUserId = currentUser.id;
  const hasAccess = useHasAccess();

  useEffect(() => {
    setDataTypes(dataTypes);
  }, [dataTypes, setDataTypes]);

  useEffect(() => {
    if (hasTracked) {
      return;
    }
    if (isPopupReportOpen) {
      trackEvent('Popup Report - Opened', {
        reportName: report.name,
        reportId: report.id,
        accessType: report.access.type,
        origin: popupOrigin,
      });
    } else {
      trackEvent('Report - Opened', {
        reportName: report.name,
        reportId: report.id,
        accessType: report.access.type,
        source: workSpace ? 'Workspace' : 'Report',
      });
    }

    setHasTracked(true);
  }, [
    hasTracked,
    isPopupReportOpen,
    report.access.type,
    report.id,
    report.name,
    trackEvent,
    popupOrigin,
    workSpace,
  ]);

  useEffect(() => {
    setHasLeftDefaultState(currentDrillDowns.length !== 0);
  }, [currentDrillDowns]);

  useEffect(() => {
    if (unSavedFilter) {
      setCurrentScope(unSavedFilter.scope);
      setCurrentDrillDowns(unSavedFilter.drillDowns);
    } else if (parentDashboardGadget) {
      setCurrentScope(parentDashboardGadget.scope);
      setCurrentDrillDowns(parentDashboardGadget.drillDowns);
      setIsDefaultFiltersDisabled(
        !!parentDashboardGadget.isDefaultFiltersDisabled,
      );
    } else if (savedFilter) {
      setCurrentScope(savedFilter.scope);
      setCurrentDrillDowns(savedFilter.drillDowns);
    } else {
      setCurrentScope(scope);
      setIsDefaultFiltersDisabled(!!report.isDefaultFiltersDisabled);
    }
  }, [
    parentDashboardGadget,
    report.isDefaultFiltersDisabled,
    savedFilter,
    scope,
    unSavedFilter,
  ]);

  const reset = () => {
    if (parentDashboardGadget) {
      setCurrentScope(parentDashboardGadget.scope);
      setCurrentDrillDowns(parentDashboardGadget.drillDowns);
      setIsDefaultFiltersDisabled(
        !!parentDashboardGadget.isDefaultFiltersDisabled,
      );
    } else if (unSavedFilter) {
      setCurrentScope(unSavedFilter.scope);
      setCurrentDrillDowns(unSavedFilter.drillDowns);
    } else {
      setCurrentScope(scope);
      setCurrentDrillDowns([]);
      setIsDefaultFiltersDisabled(!!report.isDefaultFiltersDisabled);
    }
  };

  useEffect(() => {
    if (
      access &&
      !isPopup &&
      !workSpace &&
      !isValidationReport &&
      !targetsAppContext &&
      !entityDetailsContext
    ) {
      if (
        !hasAccess({
          access,
          resource: report,
          type: 'report',
          typeId: report.id,
        })
      ) {
        navigate(appRoutes.home);
      }
    }
  }, [
    entityDetailsContext,
    access,
    currentUser,
    isPopup,
    isWallboard,
    report,
    workSpace,
    currentPermissions,
    isValidationReport,
    hasAccess,
    targetsAppContext,
    navigate,
  ]);

  const createExportSavedFilter = useCallback(async () => {
    const sf: LegacySavedFilter = {
      version: '2',
      source: 'report-export',
      dateField: dateField,
      id: aguid(),
      scope: currentScope,
      drillDowns: currentDrillDowns,
      relativeDateRange,
      dateRange,
      advancedRelativeDateRange,
    };
    await STORE.filterDefinitions
      .getLegacySavedFiltersRef({
        accountId: selectedAccountId,
      })
      .doc(sf.id)
      .set(sf);

    return sf.id;
  }, [
    dateField,
    currentScope,
    currentDrillDowns,
    relativeDateRange,
    dateRange,
    advancedRelativeDateRange,
    selectedAccountId,
  ]);

  const startEditing = () => {
    trackEvent('Report - Edit Started', {
      reportId: report.id,
      reportName: report.name,
    });
    setCurrentDrillDowns([]);
    setCurrentScope(scope);
    setDateRange(report.dateRange);
    setRelativeDateRange(report.relativeDateRange);
    setAdvancedRelativeDateRange(report.advancedRelativeDateRange);
    setWeekStartsOnOverride(report.weekStartsOnOverride);
    setIsEditing(true);
  };
  const onEditSave = () => {
    const newReport: ReportType = {
      ...report,
      canvas: currentCanvas,
      scope: currentScope,
      dateRange,
      relativeDateRange,
      advancedRelativeDateRange,
      comparison: currentComparison,
      updatedOn: DateTime.utc().toISO(),
      updatedBy: currentUser.id,
      dateField,
      weekStartsOnOverride,
      isEntityFilterEnabled,
      isDefaultFiltersDisabled,
    };
    trackEvent('Report - Edit Saved', {
      reportId: report.id,
      reportName: report.name,
    });
    setIsEditing(false);
    updateReport(report.id, withoutNulls(newReport), accountRef).then(() => {
      setIsEditing(false);
      if (setReport) {
        setReport(withoutNulls(newReport));
      }
      if (hasEditingSearchQuery()) {
        navigate({ search: '' });
      }
    });
  };

  const saveEditAs = async (name: string) => {
    const newReport: ReportType = {
      ...report,
      name,
      id: aguid(),
      canvas: currentCanvas,
      scope: currentScope,
      dateRange,
      relativeDateRange,
      advancedRelativeDateRange,
      comparison: currentComparison,
      dateField,
      weekStartsOnOverride,
      createdBy: currentUser.id,
      createdOn: DateTime.utc().toISO(),
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
      isEntityFilterEnabled,
      isDefaultFiltersDisabled,
    };

    STORE.getReportsRef({ accountId: selectedAccountId })
      .doc(newReport.id)
      .set(newReport)
      .then(() => {
        const requests = [
          addNewReportToCurrentPortal(newReport.id),
          updateRecentName({
            type: 'report',
            typeId: report.id,
            newName: name,
            userId: currentUserId,
            accountRef,
          }),
          updateFavouriteName({
            type: 'report',
            typeId: report.id,
            newName: name,
            userId: currentUserId,
            accountRef,
          }),
        ];
        Promise.all(requests).then(() => {
          navigate(
            buildReportShow({
              id: newReport.id,
              isEditing: false,
              portal: selectedPortal,
            }),
          );
        });
      });
  };

  const onEditCancel = () => {
    trackEvent('Report - Edit Cancelled', {
      reportId: report.id,
      reportName: report.name,
    });
    setIsEditing(false);
    setCurrentCanvas(canvas);
    setCurrentScope(scope);
    setIsDefaultFiltersDisabled(!!report.isDefaultFiltersDisabled);
    setCurrentDrillDowns([]);
    setWeekStartsOnOverride(report.weekStartsOnOverride);
    if (hasEditingSearchQuery()) {
      navigate({ search: '' });
    }
  };

  const saveReportAs = async (name: string) => {
    const newReport: ReportType = {
      ...report,
      name,
      dateField,
      dateRange,
      relativeDateRange,
      advancedRelativeDateRange,
      id: aguid(),
      weekStartsOnOverride,
      isEntityFilterEnabled,
      isDefaultFiltersDisabled,
    };

    await STORE.getReportsRef({ accountId: selectedAccountId })
      .doc(newReport.id)
      .set(newReport)
      .then(() => {
        addNewReportToCurrentPortal(newReport.id).then(() => {
          navigate(
            buildReportShow({ id: newReport.id, portal: selectedPortal }),
          );
        });
      });
  };

  const onDelete = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('This cannot be undone. Are you sure?')) {
      deleteReport(report.id, accountRef).then(() => {
        trackEvent('Report - Deleted');
        if (onDeleted) {
          onDeleted();
        } else {
          navigate(appRoutes.loggedIn.reports);
          alert('Report deleted');
        }
      });
    }
  }, [accountRef, navigate, onDeleted, report.id, trackEvent]);

  const exportReport = async (userIds: string[], notes: string) => {
    const reportElement = document.getElementById('report');
    if (!reportElement) {
      return;
    }
    if (!currentUserId) {
      return;
    }
    trackEvent('Report - Exported', {
      reportName: report.name,
      reportId: report.id,
    });

    const savedFilterId = await createExportSavedFilter();

    let waitTime = 5000;
    await html2canvas(reportElement).then((canvas) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          return false;
        }
        const prefix = report.name;
        const postfix = moment.utc().format('Do MMM YYYY');
        const exportName = `${prefix} - ${postfix}`.replace(/ /g, '');
        const exportId = aguid();

        const storage = firebase.storage().ref();
        const pathBase = `accounts/${accountId}/exports/${exportId}`;
        const reportPath = `${pathBase}/${exportName}.jpg`;
        const excelPaths = [] as string[];
        for (let i = 0; i < gridExportCallbacks.length; i++) {
          const fileData = await gridExportCallbacks[i].callback();
          const filePath = `${pathBase}/${fileData.fileName}.csv`;
          waitTime += 5000;

          const response = await storage
            .child(filePath)
            .putString(fileData.content, undefined, {
              contentType: 'text/csv',
            });
          excelPaths.push(response.metadata.fullPath);
        }

        const reportRef = storage.child(reportPath);
        reportRef.put(blob).then((response) => {
          const emailDateRange = `${dateScopeForEmail.startDate} - ${dateScopeForEmail.endDate}`;

          const filtering = toDrillDowns({
            plates: currentDrillDowns,
            variableDrillDowns: [],
          })
            .filter(filterTypeCheckers.isTextFilter)
            .map(
              (d) =>
                `${d.field} ${d.keywordValues ? `(${d.keywordValues})` : ''}`,
            )
            .toString()
            .replace(/,/g, ', ');

          const reportLink = window.location.href.includes('dashboards')
            ? `${window.location.origin}${window.location.pathname}?popupReportId=${report.id}&savedFilterId=${savedFilterId}`
            : `${window.location.origin}${buildReportShow({
                id: report.id,
                portal: selectedPortal,
              })}?savedFilterId=${savedFilterId}`;

          const exportData = {
            id: exportId,
            name: `${report.name} - ${moment.utc().format('Do MMM YYYY')}`,
            senderId: currentUserId,
            reportPath: response.metadata.fullPath,
            excelPaths: excelPaths.length < 1 ? [] : excelPaths,
            dateRange: emailDateRange,
            filtering: filtering === '' ? 'None' : filtering,
            reportLink,
            reportName: report.name,
            userIds,
            notes: notes === '' ? 'None' : notes,
          } as ReportExportBody;
          shareExportedReport(exportData, api).then((response) => {
            if (!response.ok) {
              captureException(new Error('Report export failed'));
            }
          });

          return;
        });
      });
    });
    await delay(waitTime);
  };

  const createAndAddDashboardWidget = async (gadget: DashboardGadget) => {
    return createDashboardGadget(gadget, selectedAccountId);
  };

  const onScroll = useCallback(() => {
    const filterBar = document.getElementById('report-filters');
    if (!filterBar || isPopup) {
      return;
    }

    if (filterBar.getBoundingClientRect().top <= 10) {
      if (filterBar.style.boxShadow === '') {
        filterBar.style.boxShadow = 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)';
      }
    } else {
      if (filterBar.style.boxShadow !== '') {
        filterBar.style.boxShadow = '';
      }
    }
  }, [isPopup]);

  return (
    <ReportContext.Provider
      value={{
        report,
        isEditing,
        currentScope,
        currentDrillDowns,
        reset,
        isExporting,
        setIsExporting,
        gridExportCallbacks,
        setGridExportCallbacks,
      }}
    >
      <GqlClientProvider>
        <CopyGadgetProvider>
          <ComparisonContext.Provider
            value={{ currentComparison, setCurrentComparison }}
          >
            <ReportDateDrillDownOptionsProvider startOfWeek={weekStartsOn}>
              <FilterPlatesProvider
                drillDowns={currentDrillDowns}
                setDrillDowns={setCurrentDrillDowns}
                scope={currentScope}
                setScope={setCurrentScope}
                dataTypes={dataTypes}
                isDefaultFiltersDisabled={isDefaultFiltersDisabled}
                setIsDefaultFiltersDisabled={setIsDefaultFiltersDisabled}
              >
                <WeekStartsOnOverrideProviderExceptForPopups
                  isPopup={isPopup}
                  weekStartsOnOverride={weekStartsOnOverride}
                >
                  <Report
                    report={report}
                    currentCanvas={currentCanvas}
                    setCurrentCanvas={setCurrentCanvas}
                    isEditing={isEditing}
                    onEditSave={onEditSave}
                    onEditCancel={onEditCancel}
                    saveReportAs={saveReportAs}
                    saveEditAs={saveEditAs}
                    startEditing={startEditing}
                    exportReport={exportReport}
                    onDelete={onDelete}
                    hasLeftDefaultState={hasLeftDefaultState}
                    createAndAddDashboardWidget={createAndAddDashboardWidget}
                    hideMenu={hideMenu}
                    weekStartsOnOverride={weekStartsOnOverride}
                    setWeekStartsOnOverride={setWeekStartsOnOverride}
                    isMobile={isMobile}
                    reportId={report.id}
                    isPopup={isPopup}
                    onScroll={onScroll}
                    isValidationReport={isValidationReport}
                    isTargetsApp={!!targetsAppContext}
                    isEntityApp={!!entityDetailsContext}
                    isWorkspace={!!workSpace}
                    isEntityFilterEnabled={isEntityFilterEnabled}
                    setIsEntityFilterEnabled={setIsEntityFilterEnabled}
                    dataTypes={dataTypes}
                  />
                </WeekStartsOnOverrideProviderExceptForPopups>
              </FilterPlatesProvider>
            </ReportDateDrillDownOptionsProvider>
          </ComparisonContext.Provider>
        </CopyGadgetProvider>
      </GqlClientProvider>
    </ReportContext.Provider>
  );
};

// We don't want this behaviour in a popup report.
// In that context, the dashboard gadget is the source of
// override, overwriting the report's override.
// This is UX/tech debt.
const WeekStartsOnOverrideProviderExceptForPopups = ({
  weekStartsOnOverride,
  isPopup,
  children,
}: {
  weekStartsOnOverride?: WeekStartsOn;
  isPopup: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  if (isPopup) {
    return <>{children}</>;
  }
  return (
    <WeekStartsOnOverrideContext.Provider
      value={{
        weekStartsOnOverride,
      }}
    >
      {children}
    </WeekStartsOnOverrideContext.Provider>
  );
};

export default withDateFilter(ReportContainer, {});
