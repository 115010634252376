import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import _ from 'lodash';

import CloudFunctionClientContext from 'contexts/CloudFunctionClientContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import updatePreviousUploads from '../../api/updatePreviousUploads';
import postUpload from '../../api/postUploads';
import getIdentifier from 'getIdentifier';
import useGetPreviousUploads from '../hooks/useGetPreviousUploads';
import usePopup from 'hooks/usePopup';
import FileUploadModal from './FileUploadModal';
import { UnsavedChangesConfirmation } from 'components/ConfirmationModal';

const FileUploadModalContainer = ({
  close,
  dataType,
  onUploadSuccess,
  onUploadFailure,
}: {
  close: () => void;
  dataType: DataManager.UploadDataType;
  onUploadSuccess: () => void;
  onUploadFailure: () => void;
}) => {
  const { api } = useContext(CloudFunctionClientContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const { previousUploads, isLoading, refreshPreviousUploads } =
    useGetPreviousUploads(dataType.dataType);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [configError, setConfigError] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [uploadedFileName, setUploadedFileName] = useState<string>();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const {
    isOpen: isConfirmOpen,
    open: openConfirm,
    close: closeConfirm,
  } = usePopup();

  const closeUploadModal = useCallback(() => {
    const hasUnsavedChanges = !successMessage && file !== undefined;

    if (!hasUnsavedChanges) {
      close();
      return;
    }

    openConfirm();
  }, [close, file, successMessage, openConfirm]);

  const onPopupCloseConfirmed = useCallback(() => {
    closeConfirm();
    close();
  }, [close, closeConfirm]);

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const file = files ? files[0] : undefined;
      if (!file || !files) {
        return;
      }

      trackEvent('Data Manager - Upload Data - File Selected', {
        type: dataType.dataType,
      });

      setFile(file);
    },
    [dataType.dataType, trackEvent],
  );

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(undefined);
        setUploadedFileName(undefined);
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }

    if (configError) {
      const timer = setTimeout(() => {
        setConfigError(undefined);
        setUploadedFileName(undefined);
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [configError, successMessage]);

  const onFileUpload = useCallback(() => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsUploading(true);
    setConfigError(undefined);
    setSuccessMessage(undefined);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    setErrors([]);
    setErrorMessage(undefined);
    postUpload({
      formData,
      headers,
      dataType: dataType.dataType,
      provider: dataType.provider,
      api,
    }).then((response) => {
      setIsUploading(false);
      setUploadedFileName(file.name);
      setFile(undefined);

      if (!response.ok) {
        onUploadFailure();
        if (response.data && response.data.error) {
          if (response.status === 415) {
            setConfigError(response.data.error.message);
          } else if (response.status === 404) {
            setConfigError(
              `Cannot access ${response.originalError.config.url}`,
            );
          } else {
            setErrorMessage(response.data.error.message);
            if (Array.isArray(response.data.error.errors)) {
              setErrors(response.data.error.errors);
            }
          }
        }
      } else {
        onUploadSuccess();
        const newPreviousUpload = {
          id: getIdentifier(),
          fileName: file.name,
          dataType: dataType.dataType,
          uploadedBy: currentUser.id,
          uploadedOn: DateTime.utc().toISO(),
        };
        updatePreviousUploads(newPreviousUpload, selectedAccountId).then(() =>
          refreshPreviousUploads(),
        );

        if (response.data && response.data.message) {
          setSuccessMessage(response.data.message);
        } else {
          setSuccessMessage(
            `${uploadedFileName || 'File '} uploaded successfully`,
          );
        }
      }
    });
  }, [
    api,
    currentUser.id,
    dataType.dataType,
    dataType.provider,
    file,
    onUploadFailure,
    uploadedFileName,
    onUploadSuccess,
    refreshPreviousUploads,
    selectedAccountId,
  ]);

  const sortedPreviousUploads = _.orderBy(
    previousUploads,
    (upload) => {
      return moment(upload.uploadedOn).format('YYYYMMDDHHmmss');
    },
    ['desc'],
  );

  return (
    <>
      <FileUploadModal
        close={closeUploadModal}
        dataType={dataType}
        file={file}
        setFile={setFile}
        onFileUpload={onFileUpload}
        isUploading={isUploading}
        inputFile={inputFile}
        onFileChange={onFileChange}
        isLoading={isLoading}
        sortedPreviousUploads={sortedPreviousUploads}
        errors={errors}
        errorMessage={errorMessage}
        configError={configError}
        successMessage={successMessage}
      />
      <UnsavedChangesConfirmation
        onConfirmed={onPopupCloseConfirmed}
        close={closeConfirm}
        isOpen={isConfirmOpen}
      />
    </>
  );
};

export default FileUploadModalContainer;
