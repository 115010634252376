import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import formatDateLabel from './V5Gadget/formatDateLabel';
import moment from 'moment';
import Cell from './V5Gadget/Matrix/Cell';
import captureException from '../services/captureException';

export const formatDateCell = (value: string | number, dateFormat?: string) => {
  switch (dateFormat) {
    case 'MM/DD-DD':
      return formatDateLabel(value, 'week', true);
    case 'MMM/DD/YY HH:mm':
      return moment.utc(value).format('MMM/DD/YY HH:mm');
    case 'MM/DD/YY HH:mm':
      return moment.utc(value).format('MM/DD/YY HH:mm');
    case 'HH:mm':
      return moment.utc(value).format('HH:mm');
    case 'MM/DD/YY':
      return moment.utc(value).format('MM/DD/YY');
    case 'MMM/DD/YY':
    default:
      return moment.utc(value).format('MMM/DD/YY');
  }
};

const sanitizeValue = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  }

  const stripped = value.replaceAll(',', '').replaceAll(' ', '');
  const isIso = stripped.split('-').length === 3;
  if (isIso) {
    return stripped;
  }
  return Number.parseInt(stripped, 10);
};

const DateCell = (props: ICellRendererParams) => {
  const { value, colDef } = props;
  const dateFormat = colDef
    ? colDef.cellRendererParams.fieldView.dateFormat
    : '';

  if (value === undefined || value === null || value === '-') {
    return (
      <Cell>
        <span>-</span>
      </Cell>
    );
  }

  try {
    const formatted = formatDateCell(sanitizeValue(value), dateFormat);

    return (
      <Cell>
        <span>{formatted}</span>
      </Cell>
    );
  } catch (ex) {
    captureException(ex);
    return (
      <Cell>
        <span>{value}</span>
      </Cell>
    );
  }
};

export default DateCell;
