import React, { useContext } from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import Icon from '../Icon';
import Colors2 from '../../../theme/Colors2';
import Colors from '../../../theme/colors';
import Tooltip from '../../../components/Tooltip';
import ContextMenuContext from './ContextMenuContext';
import { ContextMenuDiv } from './index';

export const OPTION_WIDTH = 192;

const OptionDiv = styled.div<{
  isDisabled?: boolean;
  subMenuPlacement: 'left' | 'right';
}>`
  height: 36px;
  padding: 0px 8px;
  width: ${OPTION_WIDTH}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  position: relative;

  .subnav-content {
    display: none;
    position: absolute;
    top: 0;
    left: ${OPTION_WIDTH}px;
    width: 100%;
    z-index: 1;

    ${(props) =>
      props.subMenuPlacement === 'right' &&
      `
    left: ${OPTION_WIDTH}px;
    `}
    ${(props) =>
      props.subMenuPlacement === 'left' &&
      `
    left: -${OPTION_WIDTH}px;
    `}
  }

  &:hover {
    background-color: #fbfbfb;
    .subnav-content {
      display: block;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Option = ({
  id,
  label,
  onClick,
  icon,
  subMenu,
  subMenuPlacement = 'right',
  isDanger,
  isDisabled,
  disabledMessage,
  testId,
  shouldHideInsteadOfCloseMenu,
}: {
  id?: string;
  label: string;
  onClick?: () => void;
  icon?: Kingpin.Icon;
  subMenu?: JSX.Element;
  subMenuPlacement?: 'left' | 'right';
  isDanger?: boolean;
  isDisabled?: boolean;
  disabledMessage?: string;
  testId?: string;
  shouldHideInsteadOfCloseMenu?: boolean;
}) => {
  const { closeMenu, hideMenu } = useContext(ContextMenuContext);
  const color = isDanger
    ? Colors.RADICAL_RED
    : isDisabled
      ? Colors2.Grey['4']
      : Colors2.Grey['2'];

  return (
    <Tooltip content={isDisabled ? disabledMessage : undefined}>
      <OptionDiv
        className="subnav"
        subMenuPlacement={subMenuPlacement}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          if (onClick) {
            onClick();
            if (shouldHideInsteadOfCloseMenu) {
              hideMenu();
            } else {
              closeMenu();
            }
          }
        }}
        id={id}
        isDisabled={isDisabled}
        data-testid={testId}
      >
        <Left>
          {icon && (
            <div style={{ marginRight: 8, display: 'flex' }}>
              <Icon icon={icon} color={color} width={16} height={16} />
            </div>
          )}

          <Typography.Body type={'Button Text'} color={color}>
            {label}
          </Typography.Body>
        </Left>
        <Right>
          {subMenu && (
            <>
              <Icon
                icon={'chevron-right'}
                color={color}
                width={16}
                height={16}
              />
              <div className="subnav-content">
                <ContextMenuDiv>{subMenu}</ContextMenuDiv>
              </div>
            </>
          )}
        </Right>
      </OptionDiv>
    </Tooltip>
  );
};

export default Option;
