import firebase from 'firebase/compat/app';
import { DASHBOARDS_COLLECTION } from '../constants';
import withoutNulls from '../api/search/withoutNulls';

const dashboardConverter = {
  toFirestore(dashboard: DashboardType): firebase.firestore.DocumentData {
    return withoutNulls(dashboard);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<DashboardType>,
    options: firebase.firestore.SnapshotOptions,
  ): DashboardType {
    const dashboard = snapshot.data(options);
    dashboard.variableDrillDowns = dashboard.variableDrillDowns.map((v) => ({
      ...v,
      type: 'Variable',
    }));
    return dashboard;
  },
};

const getDashboardsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(DASHBOARDS_COLLECTION)
    .withConverter(dashboardConverter);
};

export default getDashboardsRef;
