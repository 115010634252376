import React from 'react';
import _ from 'lodash';

import useUsers from '../../hooks/useUsers';
import isDefined from '../../isDefined';
import AccessUsers from './AccessUsers';

const AccessUsersContainer = ({ access }: { access: ResourceAccess }) => {
  const accountUsers = useUsers();
  const users = !access.userIds
    ? []
    : _.uniq(access.userIds)
        .map((uid) => accountUsers.find((au) => au.id === uid))
        .filter(isDefined);

  return <AccessUsers users={users} type={access.type} />;
};

export default AccessUsersContainer;
