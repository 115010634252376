import formatFloat from '../../api/getChartSeries/formatFloat';
import tinyColor from 'tinycolor2';

import chartColors from '../../theme/chartColors';
import getAllValuesFor from '../../getAllValuesFor';
import isCustomRangeInput from '../../isCustomRangeInput';
import formatDateLabel from './formatDateLabel';
import {
  getIsLastDatePartial,
  removePartialIntervalsDates,
} from '../../removePartialIntervals';
import moment from 'moment';
import base from './highchartOptions/baseOptions';
import buildTooltipFormatter from './tooltipFormatter';
import getOrderedDaysOfWeek from '../../hooks/useMetricMatrix/getOrderedDaysOfWeek';
import mapGraphqlDayNumberToString from '../../mapGraphqlDayNumberToString';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import isDefined from '../../isDefined';

const ORDERED_HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

const getSeriesDef = (chartDef: V5ChartDefinition, metricId: string) =>
  chartDef.series.find((s) => s.metricId === metricId);

const getSeriesDefIndex = (chartDef: V5ChartDefinition, metricId: string) =>
  chartDef.series.findIndex((s) => s.metricId === metricId);

const getYAxisNumberFor = (
  seriesDef: V5ChartDefinitionSeries | undefined,
  allSeries: V5ChartDefinitionSeries[],
) => {
  if (!seriesDef || !seriesDef.plotOnSeparateAxis) {
    return 0;
  }

  const seriesAxisLookup: { [seriesId: string]: number | undefined } = {};
  let current = 1;
  allSeries.forEach((series, index) => {
    if (index === 0) {
      seriesAxisLookup[series.id] = 0;
    } else if (series.plotOnSeparateAxis) {
      seriesAxisLookup[series.id] = current;
      current++;
    }
  });

  return seriesAxisLookup[seriesDef.id] || 0;
};

const getSeriesTypeFor = (seriesDef: V5ChartDefinitionSeries | undefined) => {
  if (!seriesDef) {
    return 'column';
  }

  return seriesDef.type;
};

const getIsOppositeFor = (seriesDef: V5ChartDefinitionSeries | undefined) => {
  if (!seriesDef) {
    return undefined;
  }

  return seriesDef.opposite;
};

const getSeriesFor = ({
  data,
  chartDef,
  isCombo,
  dateScope,
  autoInterval,
  filterInput,
  weekStartsOn,
}: {
  data: V5ChartData;
  chartDef: V5ChartDefinition;
  isCombo: boolean;
  dateScope?: DateRangeInput;
  autoInterval?: AutoInterval;
  filterInput?: FilterInput;
  weekStartsOn: WeekStartsOn;
}): BarSeriesItem[] => {
  const rawMetricSeries = Object.entries(data).map(
    ([metricId, { metric, response: baseResponse }]) => {
      const seriesDef = getSeriesDef(chartDef, metricId);
      const seriesDefIndex = getSeriesDefIndex(chartDef, metricId);
      const yAxis = getYAxisNumberFor(seriesDef, chartDef.series);
      const type = getSeriesTypeFor(seriesDef);
      const metricIds = (() => {
        if (metricTypeCheckers.isCompoundMetric(metric)) {
          return metric.metricIds;
        }

        return [metric.id];
      })();

      const response = chartDef.excludePartialIntervals
        ? responseWithoutPartialInterval(
            baseResponse,
            dateScope,
            autoInterval
              ? autoInterval.interval
              : chartDef.trendByCalendarInterval,
            filterInput,
          )
        : baseResponse;

      const marker =
        type === 'line'
          ? {
              enabled: false,
              symbol: 'circle',
              fillColor: 'white',
              lineWidth: 1,
              lineColor: chartColors[seriesDefIndex],
              radius: 3,
            }
          : undefined;

      const isDayOfWeek =
        chartDef.dimensionA && chartDef.dimensionA.isGroupByDayOfWeek;
      const data = (() => {
        const responseToUse = (() => {
          if (chartDef.trendByHourInterval) {
            return ORDERED_HOURS.map((hour) => {
              return response.find((r) => r['key'].toString() === hour);
            }).map((r) => {
              if (r) {
                return r;
              }
              return {};
            });
          }

          if (!isDayOfWeek) {
            return response;
          }
          const orderedDays = getOrderedDaysOfWeek(weekStartsOn);
          return orderedDays
            .map((day) => {
              return response.find(
                (r) =>
                  mapGraphqlDayNumberToString(r['dayOfWeek'].toString()) ===
                  day,
              );
            })
            .map((r) => (r ? r : {}));
          // Return an empty obj to ensure the axis aligns in partial weeks
        })();

        return Object.values(responseToUse).map((i) =>
          formatFloat(i[metricId], metric.formatting.precision),
        );
      })();

      return {
        name: seriesDef
          ? seriesDef.displayName
            ? seriesDef.displayName
            : metric.name
          : metric.name,
        data,
        metric,
        metricId: metric.id,
        marker,
        prefix: metric.formatting.prefix,
        postfix: metric.formatting.postfix,
        interval: chartDef.trendByCalendarInterval,
        colorIndex: seriesDefIndex,
        yAxis: isCombo ? yAxis : undefined,
        type,
        id: metric.id,
        metricIds,
      } as BarSeriesItem;
    },
  );

  const averageSeries = Object.entries(data)
    .filter(([metricId]) => {
      const seriesDef = getSeriesDef(chartDef, metricId);
      if (!seriesDef) {
        return false;
      }
      return seriesDef.plotAverage;
    })
    .map(([metricId, { metric, response: baseResponse }]) => {
      const seriesDef = getSeriesDef(chartDef, metricId);
      const seriesDefIndex = getSeriesDefIndex(chartDef, metricId);
      const yAxis = getYAxisNumberFor(seriesDef, chartDef.series);
      const response = chartDef.excludePartialIntervals
        ? responseWithoutPartialInterval(
            baseResponse,
            dateScope,
            autoInterval
              ? autoInterval.interval
              : chartDef.trendByCalendarInterval,
            filterInput,
          )
        : baseResponse;
      const sum = response.reduce(
        (
          accumulator: number,
          currentValue: { [key: string]: string | number | FilterInput },
        ) => accumulator + (currentValue[metricId] as number),
        0,
      );
      const average = formatFloat(
        sum / response.length,
        metric.formatting.precision,
      );
      const metricIds = (() => {
        if (metricTypeCheckers.isCompoundMetric(metric)) {
          return metric.metricIds;
        }

        return [metric.id];
      })();

      const marker = {
        enabled: false,
        symbol: 'circle',
        fillColor: 'white',
        lineWidth: 1,
        lineColor: chartColors[seriesDefIndex],
        radius: 3,
      };

      return {
        name: `${metric.name} (average)`,
        type: 'line',
        metric,
        metricId: metric.id,
        prefix: metric.formatting.prefix,
        postfix: metric.formatting.postfix,
        interval: chartDef.trendByCalendarInterval,
        yAxis: isCombo ? yAxis : undefined,
        dashStyle: 'dash',
        showInLegend: true,
        linkedTo: metric.id,
        data: response.map(() =>
          formatFloat(average, metric.formatting.precision),
        ),
        colorIndex: seriesDefIndex,
        metricIds,
        marker,
      } as BarSeriesItem;
    });

  const trendSeries = Object.entries(data)
    .filter(([metricId]) => {
      const seriesDef = getSeriesDef(chartDef, metricId);
      if (!seriesDef) {
        return false;
      }
      return seriesDef.plotTrend;
    })
    .map(([metricId, { metric, response: baseResponse }]) => {
      const seriesDef = getSeriesDef(chartDef, metricId);
      const seriesDefIndex = getSeriesDefIndex(chartDef, metricId);
      const yAxis = getYAxisNumberFor(seriesDef, chartDef.series);
      const response = chartDef.excludePartialIntervals
        ? responseWithoutPartialInterval(
            baseResponse,
            dateScope,
            autoInterval
              ? autoInterval.interval
              : chartDef.trendByCalendarInterval,
            filterInput,
          )
        : baseResponse;
      const sumX = response.reduce(
        (accumulator: number, _current, index) => accumulator + index,
        0,
      );
      const sumY = response.reduce(
        (
          accumulator: number,
          currentValue: { [key: string]: string | number | FilterInput },
        ) => accumulator + (currentValue[metricId] as number),
        0,
      );
      const sumXSquared = response.reduce(
        (
          accumulator: number,
          currentValue: { [key: string]: string | number | FilterInput },
          index,
        ) => accumulator + index * index,
        0,
      );
      const sumXTimesY = response.reduce(
        (
          accumulator: number,
          currentValue: { [key: string]: string | number | FilterInput },
          index,
        ) => accumulator + (currentValue[metricId] as number) * index,
        0,
      );
      const numPoints = response.length;

      const slopeTop = numPoints * sumXTimesY - sumX * sumY;
      const slopeBot = numPoints * sumXSquared - sumX * sumX;
      const slope = slopeTop / slopeBot;

      const intercept = (sumY - slope * sumX) / numPoints;

      const findY = (x: number) => slope * x + intercept;
      const data = response.map((_value, index) =>
        formatFloat(findY(index), metric.formatting.precision),
      );
      const metricIds = (() => {
        if (metricTypeCheckers.isCompoundMetric(metric)) {
          return metric.metricIds;
        }

        return [metric.id];
      })();

      const marker = {
        enabled: false,
        symbol: 'circle',
        fillColor: 'white',
        lineWidth: 1,
        lineColor: chartColors[seriesDefIndex],
        radius: 3,
      };

      const getMetricName = ({
        seriesDef,
        metric,
      }: {
        seriesDef?: V5ChartDefinitionSeries;
        metric: Metrics.Metric;
      }) => {
        if (seriesDef) {
          return `${seriesDef.displayName || metric.name} (trend)`;
        }
      };

      return {
        name: getMetricName({ seriesDef, metric }),
        type: 'line',
        metric,
        metricId: metric.id,
        prefix: metric.formatting.prefix,
        postfix: metric.formatting.postfix,
        interval: chartDef.trendByCalendarInterval,
        yAxis: isCombo ? yAxis : undefined,
        dashStyle: 'dash',
        showInLegend: true,
        linkedTo: metric.id,
        data,
        colorIndex: seriesDefIndex,
        metricIds,
        marker,
      } as BarSeriesItem;
    });

  return [...rawMetricSeries, ...averageSeries, ...trendSeries];
};

const getHasAnySeparateAxis = (chartDef: V5ChartDefinition) => {
  const { series } = chartDef;
  return series.some((s) => s.plotOnSeparateAxis);
};

const getSeriesColor = (index: number, chartDef: V5ChartDefinition) => {
  if (!getHasAnySeparateAxis(chartDef)) {
    return '#000000';
  }
  return tinyColor(chartColors[index]).saturate(20).toHexString();
};

const getYAxisFor = (
  series: BarSeriesItem[],
  chartDef: V5ChartDefinition,
  fontSize: number,
  isCombo: boolean,
) => {
  if (isCombo) {
    const usedSeriesItems: V5ChartDefinitionSeries[] = [];
    return series
      .map((s, index) => {
        const seriesDef = getSeriesDef(chartDef, s.metricId);
        if (!seriesDef) {
          return undefined;
        }
        if (index !== 0 && !seriesDef.plotOnSeparateAxis) {
          return undefined;
        }
        if (usedSeriesItems.some((s) => s.id === seriesDef.id)) {
          return undefined;
        }
        usedSeriesItems.push(seriesDef);
        return {
          max: seriesDef.maxY,
          min: seriesDef.minY,
          opposite: seriesDef.opposite,
        };
      })
      .filter(isDefined);
  }

  return series.map((s) => {
    const seriesDef = getSeriesDef(chartDef, s.metricId);

    const n = getYAxisNumberFor(seriesDef, chartDef.series);
    return {
      title: {
        text: getHasAnySeparateAxis(chartDef) ? s.metric.name : '',
        style: {
          fontSize,
          color: getSeriesColor(n, chartDef),
        },
        enabled: false,
      },
      labels: {
        style: {
          fontSize,
          color: getSeriesColor(n, chartDef),
        },
      },
      max: seriesDef?.maxY,
      min: seriesDef?.minY,
      opposite: getIsOppositeFor(seriesDef),
    };
  });
};

const responseWithoutPartialInterval = (
  response: { [key: string]: string | number | FilterInput }[],
  dateScope?: DateRangeInput,
  interval?: FleetOps.Interval,
  filterInput?: FilterInput,
): { [key: string]: string | number | FilterInput }[] => {
  if (response.length === 0) {
    return response;
  }
  // Last item
  const lastItem = response[response.length - 1];
  const lastItemDate = lastItem ? lastItem['date'] : undefined;
  if (!lastItemDate || typeof lastItemDate === 'object') {
    return response;
  }

  const isLastItemPartial = getIsLastDatePartial({
    date: lastItemDate,
    dateScope,
    interval,
  });

  // First item
  const firstItemDate = response[response.length - 1]['date'] as string;
  if (!firstItemDate) {
    return response;
  }

  const isFirstItemPartial = (() => {
    if (!interval || interval === 'auto' || !filterInput || !dateScope) {
      return false;
    }

    const dateRangeStart = dateScope.startDate;
    const firstItemDate = response[0]['date'] as string;
    return moment.utc(firstItemDate).isBefore(moment.utc(dateRangeStart));
  })();

  if (isFirstItemPartial && isLastItemPartial) {
    return response.slice(1, response.length - 1);
  } else if (isFirstItemPartial) {
    return response.slice(1);
  } else if (isLastItemPartial) {
    return response.slice(0, response.length - 1);
  } else {
    return response;
  }
};

const toBarSeries = (
  data: V5ChartData,
  chartDef: V5ChartDefinition,
  isCombo: boolean,
  unitsLocale: string,
  dateScope: DateRangeInput,
  fontSize: number,
  weekStartsOn: WeekStartsOn,
  formatMetric: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string,
  autoInterval?: AutoInterval,
): BarSeries => {
  const filterInput = (() => {
    try {
      return Object.values(data)[0].response[0].filterInput as FilterInput;
    } catch (ex) {
      return undefined;
    }
  })();
  const series = getSeriesFor({
    data,
    chartDef,
    isCombo,
    dateScope,
    autoInterval,
    weekStartsOn,
    filterInput,
  });
  const yAxis = getYAxisFor(series, chartDef, fontSize, isCombo);

  if (chartDef.groupByDayOfWeek) {
    const categories = getOrderedDaysOfWeek(weekStartsOn);
    return {
      xAxis: {
        categories,
        labels: {
          style: {
            fontSize,
          },
        },
      },
      series,
      yAxis,
    };
  }

  if (chartDef.trendByHourInterval) {
    return {
      xAxis: {
        categories: ORDERED_HOURS,
        labels: {
          style: {
            fontSize,
          },
        },
      },
      series,
      yAxis,
    };
  }

  if (chartDef.trendByCalendarInterval || chartDef.trendByFixedIntervalDays) {
    const hasDateAxis =
      chartDef.trendByCalendarInterval &&
      (chartDef.trendByCalendarInterval !== 'auto' || !!autoInterval);

    const allDates = getAllValuesFor(data, 'date');
    const interval = autoInterval
      ? autoInterval.interval
      : chartDef.trendByCalendarInterval;
    const dateScope = (() => {
      try {
        return Object.values(data)[0].response[0].dateScope as DateRangeInput;
      } catch (ex) {
        return undefined;
      }
    })();
    const categories = chartDef.excludePartialIntervals
      ? removePartialIntervalsDates(allDates, interval, dateScope)
      : allDates;

    const isComboLine =
      isCombo && chartDef.series.some((s) => s.type === 'line');
    return {
      xAxis: {
        labels: {
          style: {
            whiteSpace: hasDateAxis ? 'nowrap' : undefined,
            fontSize,
          },
          step: Math.ceil(series[0] ? series[0].data.length / 7 : 2),
          autoRotation: hasDateAxis ? 0 : undefined,
          formatter: function () {
            if (hasDateAxis && chartDef.trendByCalendarInterval) {
              if (isComboLine) {
                return moment
                  .utc(this.value)
                  .format(
                    chartDef.trendByCalendarInterval === 'hour'
                      ? 'MM/D ha'
                      : 'MM/D',
                  );
              } else {
                // @ts-ignore
                return formatDateLabel(
                  this.value,
                  autoInterval
                    ? autoInterval.interval
                    : chartDef.trendByCalendarInterval,
                  true,
                );
              }
            }
            // @ts-ignore
            return this.value;
          },
        },
        categories,
      },
      tooltip:
        isComboLine && series.length === 1
          ? {
              ...base(formatMetric).tooltip,
              useHTML: true,
              // @ts-ignore
              formatter: buildTooltipFormatter({
                series,
                autoInterval,
                data,
                formatMetric,
              }),
            }
          : {
              ...base(formatMetric).tooltip,
            },
      series,
      yAxis: isCombo
        ? yAxis
        : yAxis.map((a) => ({
            ...a,
            title: { enabled: false },
          })),
    };
  }
  if (chartDef.dimensionA) {
    if (chartDef.dimensionA.rangeInput) {
      const groupByField = 'key';
      if (isCustomRangeInput(chartDef.dimensionA.rangeInput)) {
        const categories = getAllValuesFor(data, groupByField);
        return {
          xAxis: {
            categories,
            labels: {
              style: {
                fontSize,
              },
            },
          },
          series: series,
          yAxis: isCombo
            ? yAxis
            : yAxis.map((a) => ({
                ...a,
                title: { enabled: false },
                labels: {
                  style: {
                    fontSize,
                  },
                },
              })),
        };
      } else {
        const categories = getAllValuesFor(data, groupByField);
        const interval = chartDef.dimensionA.rangeInput.fixed;
        return {
          xAxis: {
            categories: categories.map((c, index) => {
              return `${index * interval} - ${(index + 1) * interval}`;
            }),
            labels: {
              style: {
                fontSize,
              },
            },
          },
          series: series,
          yAxis: isCombo
            ? yAxis
            : yAxis.map((a) => ({
                ...a,
                title: { enabled: false },
                labels: {
                  style: {
                    fontSize,
                  },
                },
              })),
        };
      }
    } else {
      const groupByField = chartDef.dimensionA.field;
      const categories = getAllValuesFor(data, groupByField);
      return {
        xAxis: {
          categories,
          labels: {
            style: {
              fontSize,
            },
          },
        },
        series: series,
        yAxis: isCombo
          ? yAxis
          : yAxis.map((a) => ({
              ...a,
              title: { enabled: false },
              labels: {
                style: {
                  fontSize,
                },
              },
            })),
      };
    }
  }

  return {
    xAxis: {
      categories: [],
      labels: {},
    },
    series: [],
    yAxis: [],
  };
};

export default toBarSeries;
