import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Colors2 from '../../theme/Colors2';
import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip';
import Typography from './Typography';

type ButtonSize = 'Small' | 'Medium';
export type ButtonType =
  | 'Primary'
  | 'Secondary'
  | 'Tertiary'
  | 'Ghost'
  | 'Danger'
  | 'Danger Secondary';

interface ButtonProps {
  /**
   * How large should the button be?
   */
  size: ButtonSize;

  /**
   * What type of button this button?
   */
  type: ButtonType;

  /**
   * Button label (optional)
   */
  label?: string;

  /**
   * Icon (optional)
   */
  icon?: Kingpin.Icon;

  /**
   * Icon after (optional)
   */
  iconAfter?: Kingpin.Icon;

  /**
   * Is this button waiting on something? (optional)
   */
  isLoading?: boolean;

  /**
   * Is this button disabled? (optional)
   */
  isDisabled?: boolean;

  /**
   * Tooltip to show when button is disabled (optional)
   */
  isDisabledMessage?: string;

  /**
   * Click handler (options)
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;

  /**
   * Id for tests
   */
  testId?: string;
}

const PRIMARY_BACKGROUND_COLOR = '#1A1919';
const SECONDARY_BACKGROUND_COLOR = '#FFFFFF';
const TERTIARY_BACKGROUND_COLOR = '#F6F6F6';
const GHOST_BACKGROUND_COLOR = 'transparent';
export const GHOST_HOVER_BACKGROUND_COLOR = '#EEEFF1';
const DANGER_BACKGROUND_COLOR = '#F57F86';

const PRIMARY_TEXT_COLOR = Colors2.Grey['10'];
const SECONDARY_TEXT_COLOR = '#333333';
const TERTIARY_TEXT_COLOR = '#333333';
const GHOST_TEXT_COLOR = Colors2.Secondary.info;
const DANGER_TEXT_COLOR = 'white';
const DANGER_SECONDARY_TEXT_COLOR = '#FF3366';

const getBackgroundColor = (type: ButtonType) => {
  switch (type) {
    case 'Primary':
      return PRIMARY_BACKGROUND_COLOR;
    case 'Secondary':
    case 'Danger Secondary':
      return SECONDARY_BACKGROUND_COLOR;
    case 'Tertiary':
      return TERTIARY_BACKGROUND_COLOR;
    case 'Ghost':
      return GHOST_BACKGROUND_COLOR;
    case 'Danger':
      return DANGER_BACKGROUND_COLOR;
  }
};

const getBorder = (type: ButtonType) => {
  switch (type) {
    case 'Secondary':
    case 'Danger Secondary':
      return '1px solid #E0E0E0';
    case 'Tertiary':
      return '1px solid #E0E0E0';
    case 'Primary':
    case 'Ghost':
    case 'Danger':
      return 'unset';
  }
};

const getPadding = ({
  size,
  hasLabel,
}: {
  size: ButtonSize;
  hasLabel: boolean;
}) => {
  if (size === 'Small') {
    if (hasLabel) {
      return '4px 8px';
    }
    return '0px';
  } else if (size === 'Medium') {
    if (hasLabel) {
      return '6px';
    }
    return '0px';
  }

  return 'unset';
};

const getHeight = ({ type, size }: { type: ButtonType; size: ButtonSize }) => {
  if (type === 'Ghost') {
    if (size === 'Small') {
      return 20;
    }
    return 24;
  }

  if (size === 'Small') {
    return 32;
  }
  return 36;
};

const ButtonDiv = styled.div<{
  type: ButtonType;
  size: ButtonSize;
  hasLabel: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  background-color: ${(props) => getBackgroundColor(props.type)};
  border: ${(props) => getBorder(props.type)};
  padding: ${(props) =>
    getPadding({ size: props.size, hasLabel: props.hasLabel })};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => getHeight({ type: props.type, size: props.size })}px;
  width: ${(props) =>
    props.hasLabel
      ? 'unset'
      : getHeight({ type: props.type, size: props.size })}px;

  ${(props) =>
    props.type !== 'Ghost' &&
    `
    box-shadow:
      0px 3px 2px -1px rgba(0, 0, 0, 0.02),
      0px 1px 1px -1px rgba(0, 0, 0, 0.04);
  `}

  svg {
    margin-right: ${(props) => (props.hasLabel ? '4px' : '0px')};
    width: 16px;
    height: 16px;
  }

  cursor: ${(props) =>
    props.isLoading ? 'wait' : props.isDisabled ? 'not-allowed' : 'pointer'};

  opacity: ${(props) => (props.isDisabled ? 0.7 : 1)};

  &:hover {
    opacity: ${(props) => (props.isLoading ? 1 : 0.7)};
    ${(props) =>
      props.type === 'Ghost' &&
      `background-color: ${GHOST_HOVER_BACKGROUND_COLOR};`}
  }
`;

const getTextColor = (type: ButtonType, hasLabel?: boolean) => {
  switch (type) {
    case 'Primary':
      return PRIMARY_TEXT_COLOR;
    case 'Secondary':
      return SECONDARY_TEXT_COLOR;
    case 'Tertiary':
      return TERTIARY_TEXT_COLOR;
    case 'Ghost':
      if (hasLabel) {
        return GHOST_TEXT_COLOR;
      } else {
        return SECONDARY_TEXT_COLOR;
      }
    case 'Danger':
      return DANGER_TEXT_COLOR;
    case 'Danger Secondary':
      return DANGER_SECONDARY_TEXT_COLOR;
  }
};

/**
 * Primary UI component for user interaction
 */
const Button = ({
  size,
  icon,
  iconAfter,
  label,
  type,
  onClick,
  isLoading,
  isDisabled,
  isDisabledMessage,
  testId,
}: ButtonProps) => {
  const hasLabel = !!label && label.length > 0;
  const textColor = getTextColor(type, hasLabel);

  return (
    <Tooltip
      content={isDisabled && isDisabledMessage ? isDisabledMessage : undefined}
    >
      <ButtonDiv
        size={size}
        type={type}
        onClick={isLoading || isDisabled ? undefined : onClick}
        hasLabel={hasLabel}
        isLoading={isLoading}
        isDisabled={isDisabled}
        data-testid={testId}
      >
        {icon && (
          <div style={{ marginRight: label ? 4 : 0, display: 'flex' }}>
            <Icon color={textColor} icon={icon} />
          </div>
        )}
        <Typography.Body type={'Button Text'} color={textColor}>
          {label}
        </Typography.Body>
        {isLoading && (
          <div style={{ marginLeft: 4 }}>
            <Loading isTiny />
          </div>
        )}
        {iconAfter && (
          <div style={{ marginLeft: 4, display: 'flex' }}>
            <Icon color={textColor} icon={iconAfter} />
          </div>
        )}
      </ButtonDiv>
    </Tooltip>
  );
};

export default Button;
