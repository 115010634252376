import React, { useContext } from 'react';
import Actions from './Actions';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';
import useVisualisationUsageInfo from '../../../hooks/useGadgetUsageInfo';

const useCanDelete = () => {
  const { draftChart, selectedSource } = useContext(V5GadgetFormContext);
  const { reports } = useVisualisationUsageInfo(draftChart);
  return reports.length === 0 && selectedSource !== 'global';
};

const ActionsContainer = () => {
  const { onSave, onDelete, isEditing, isLoading, onSaveAs, series } =
    useContext(V5GadgetFormContext);
  const canDelete = useCanDelete();
  const hasErrors = !!series.find(
    (s) => s.minY !== undefined && s.maxY !== undefined && s.minY >= s.maxY,
  );

  return (
    <Actions
      onSave={onSave}
      onSaveAs={onSaveAs}
      onDelete={onDelete}
      isEditing={isEditing}
      isDisabled={isLoading || hasErrors}
      canDelete={canDelete}
    />
  );
};

export default ActionsContainer;
