import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import ExportModal from '../ReportMenu/ExportModal';
import Tooltip from '../Tooltip';
import Button from 'kingpin/atoms/Button';

const ExportReportButton = ({
  onExport,
  isExportingModalOpen,
  closeExportingModal,
  isExporting,
  exportReportUserIds,
  setExportReportUserIds,
  startExport,
  notes,
  onNotesChanged,
}: {
  onExport: () => void;
  isExportingModalOpen: boolean;
  closeExportingModal: () => void;
  isExporting: boolean;
  exportReportUserIds: string[];
  setExportReportUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  startExport: () => void;
  notes: string;
  onNotesChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}) => (
  <React.Fragment>
    <Tooltip content={'Share'}>
      <Button type="Secondary" size="Small" onClick={onExport} icon="share" />
    </Tooltip>
    <ModalTransition>
      {isExportingModalOpen && (
        <Modal
          onClose={closeExportingModal}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <ExportModal
            isExporting={!!isExporting}
            selectedUserIds={exportReportUserIds}
            setSelectedUserIds={setExportReportUserIds}
            startExport={startExport}
            notes={notes}
            onNotesChanged={onNotesChanged}
            close={closeExportingModal}
          />
        </Modal>
      )}
    </ModalTransition>
  </React.Fragment>
);

export default ExportReportButton;
